import building from './buildings';
import geographicalLevel from './geographical-level';
import infrastructure from './infrastructure';
import marker from '@/apps/features/map/layer-config/marker';
import baseData from '@/apps/features/map/layer-config/base-data';

export default {
  ...baseData,
  ...geographicalLevel,
  ...infrastructure,
  ...building,
  ...marker,
};

<template>
  <div class="space-y-5">
    <InputSearch
      class="w-[565px]"
      placeholder="Nach Vertriebsgebiet oder Ersteller suchen"
      :search-keys="['title', 'created_by']"
      :items="leadLists"
      @update:search="filteredLeadLists = $event"
    />
    <VuetifyDataTable
      :item-data="filteredLeadLists"
      :headers="headers"
      expanded-background="bg-subtle"
      class="project-list-table"
      :fixed-header="true"
      style="min-height: calc(100vh - 290px)"
    >
      <template #title="{ item }">
        <p class="subtitle-2">
          {{ item.title }}
        </p>
      </template>
      <template #created_at="{ item }">
        <div>
          {{ new Date(item.created_at).toLocaleDateString('de-DE') }}
        </div>
      </template>
      <template #state="{ item }">
        <div class="flex justify-center items-center">
          <ToolTip :tooltip-text="getListProgressIcon(item.state).label">
            <div
              class="rounded p-0.5"
              :class="getListProgressIcon(item.state).fill"
            >
              <IconWrapper :icon="getListProgressIcon(item.state).icon" />
            </div>
          </ToolTip>
        </div>
      </template>
      <template #functions="{ item }">
        <div class="flex justify-start items-center gap-4">
          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="tune"
            class="cursor-pointer"
            type="round"
            @click="toggleFilterOverview(item)"
          />

          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="delete_forever"
            class="cursor-pointer"
            type="round"
          />
          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="map"
            class="cursor-pointer"
            type="round"
          />
          <IconWrapper
            :size="32"
            type="round"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="file_download"
            class="cursor-pointer"
          />
        </div>
      </template>
      <template #expanded-row="{ item }">
        <EvaluationExportExpanded :lead-list="item" />
      </template>
    </VuetifyDataTable>

    <EvaluationExportFilterSettings v-model="filterOverviewOpen" :filter-set />
  </div>
</template>

<script setup>
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import { ref } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import EvaluationExportExpanded from '@/apps/usecase-4/evaluation/EvaluationExportExpanded.vue';
import EvaluationExportFilterSettings from '@/apps/usecase-4/evaluation/components/EvaluationExportFilterSettings.vue';
import { restFetch } from '@/utils/rest-utils';
import { EvaluationFiltersetDto } from './components/evaluation-filterset-dto';
import axios from '@/utils/axiosHelper';
import { getListProgressIcon } from '@/apps/usecase-4/utils';

const props = defineProps({
  salesRegionId: {
    type: String,
    required: true,
  },
});

const leadListData = await axios({
  url: `/api/usecase-4/export/${props.salesRegionId}/leadlists/`,
  method: 'GET',
});
const leadLists = leadListData.data.results;
const filteredLeadLists = ref(leadLists);

const headers = [
  {
    title: 'Leadliste',
    key: 'title',
    width: '200px',
    align: 'left',
  },
  {
    title: 'Notiz',
    key: 'description',
    align: 'left',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    width: '150px',
    align: 'center',
  },
  {
    title: 'Erstellt von',
    key: 'created_by',
    width: '200px',
    align: 'left',
  },
  {
    title: 'Status',
    key: 'state',
    width: '110px',
    align: 'center',
  },
  {
    title: '',
    key: 'functions',
    width: '230px',
    align: 'end',
    sortable: false,
  },
];

const filterOverviewOpen = ref(false);
const filterSet = ref(null);

async function toggleFilterOverview(item) {
  filterSet.value = await restFetch(EvaluationFiltersetDto, item.filterset);
  filterOverviewOpen.value = !filterOverviewOpen.value;
}
</script>

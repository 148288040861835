<template>
  <VuetifyDialog
    v-model="showModal"
    :hide-close-button="true"
    :persistent="true"
    :overflow-visible="true"
    custom-classes="rounded-[8px]"
    title="Projekt auswählen"
  >
    <template #content>
      <div class="flex flex-col gap-5">
        <FormValidator v-model="isFormValid">
          <DropDown
            v-model="selectedSalesRegionName"
            :initial-selection="true"
            :items-data="salesRegionNames"
            :rules="{ required: true }"
            label="Projekt"
          />
        </FormValidator>
        <ButtonEl
          :disabled="!isFormValid"
          text="Auswählen"
          class="w-fit"
          @click="submit()"
        />
      </div>
    </template>
  </VuetifyDialog>
</template>

<script setup>
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import { ref, computed } from 'vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import FormValidator from '@/components/FormValidator.vue';
import { useRouter } from 'vue-router';
import { useSalesRegionsStore } from '../sales-regions/sales-regions-store';

const router = useRouter();
const showModal = ref(true);

const salesRegionStore = useSalesRegionsStore();
await salesRegionStore.fetchSalesRegions();

const salesRegions = computed(() => salesRegionStore.salesRegions);

const salesRegionNames = computed(() =>
  salesRegions.value.map(({ title }) => title),
);

const selectedSalesRegionName = ref('');
const isFormValid = ref(false);

const submit = () => {
  const { id } = salesRegions.value.find(
    ({ title }) => title === selectedSalesRegionName.value,
  );

  router.push({
    name: 'mapView4',
    params: { salesRegionId: id, leadListId: 'null' },
  });
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="h-[185px] flex flex-col gap-[4px]">
    <transition-group
      name="detail-card-transition"
      tag="div"
      class="grid auto-cols-fr grid-flow-col gap-[4px] h-[136px] z-10"
    >
      <RouterLink
        v-for="tab in tabs"
        :key="tab.key"
        :to="tabRoutes[tab.key]"
        class="text-white rounded-lg p-2.5 hyphens-auto detail-card relative"
        :style="{ backgroundColor: tab.color }"
        :class="{
          'active standard-elevation-3 pointer-events-none': isTabActive(tab),
          'standard-elevation-1': !isTabActive(tab),
          'pointer-events-none opacity-40': tab.disabled,
        }"
      >
        <Component :is="isTabActive(tab) ? 'h3' : 'h4'" class="p-1">
          {{ tab.name }}
        </Component>
        <div class="flex justify-between gap-2">
          <IconWrapper
            :icon="tab.icon"
            :class="{ 'mx-auto pb-5': isTabActive(tab) }"
            :size="isTabActive(tab) ? 60 : 24"
          />
          <IconWrapper
            v-if="!isTabActive(tab)"
            icon="arrow_forward"
            type="round"
          />
        </div>
      </RouterLink>
    </transition-group>
    <div
      v-if="activeCard"
      class="w-full h-full rounded-lg flex items-center p-2 standard-elevation-1"
      :class="
        activeCard.index === tabs.length - 1 ? 'justify-start' : 'justify-end'
      "
      :style="{ backgroundColor: activeCard?.color }"
    ></div>
  </div>
</template>
<script setup>
import IconWrapper from '../../components/IconWrapper/IconWrapper.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useGoBack } from '@/composables/useGoBack';

const props = defineProps({
  tabs: {
    type: Array,
  },
  routeParameter: {
    type: [String, Number],
  },
  urlDepth: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['update-active-tab']);

const goBack = useGoBack();
const route = useRoute();

const activeCard = computed(() => {
  const activeTab = props.tabs.reduce((result, tab, index) => {
    if (route.path.split('/').at(-1).includes(tab.subUrl)) {
      result = { ...tab, index };
    }
    return result;
  }, {});
  emit('update-active-tab', activeTab);
  return activeTab;
});

const tabRoutes = computed(() => {
  return props.tabs.reduce((acc, tab) => {
    // Extract base path from route.fullPath by splitting and reconstructing
    const basePath = route.fullPath
      .split('/')
      .slice(0, props.urlDepth)
      .join('/');
    let url = `${basePath}/${tab.subUrl}`;
    if (tab.hasExtraRouterParameter && props.routeParameter) {
      url += `-${props.routeParameter}`;
    }
    acc[tab.key] = url;
    return acc;
  }, {});
});

function isTabActive(tab) {
  return route.path.split('/').at(-1).includes(tab.subUrl);
}
</script>

<style lang="scss" scoped>
.detail-card {
  transition: all 0.2s ease;
  @apply flex flex-col justify-between;
}

.detail-card {
  height: inherit;

  &.active {
    height: 184px;
    align-items: center;
  }
}
</style>

export class ClusterFiltersetDto {
  static BASE_URL = '/api/usecase-2/filterset-cluster/';

  id = null;
  min_power_enbw_kw = 0;
  max_power_enbw_kw = null;
  name = null;
  is_default = false;
  min_count_projects_enbw = 0;
  max_count_projects_enbw = null;
  min_connection_length_enbw_m = 0;
  max_connection_length_enbw_m = null;
  only_favorites = false;
  also_external_projects = false;
  allowed_review_states = ['TODO', 'YES', 'NO'];
  analysis = null;
  municipality = null;
}

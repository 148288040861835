<template>
  <div class="flex flex-col gap-5 p-1">
    <div
      v-for="item in props.data"
      :key="item.id"
      class="standard-elevation-0-dark rounded-[4px] p-2.5 flex items-center justify-between gap-2.5"
    >
      <IconWrapper
        :size="48"
        :icon="IconFileCopy"
        fill="text-color1"
        class="mb-auto"
      />

      <div v-if="item.description" class="flex flex-col gap-2.5 mr-auto">
        <h6 class="text-color1 leading-normal">{{ item.title }}</h6>
        <div class="body-3 text-neutral leading-normal">
          {{ item.description }}
        </div>
      </div>
      <h5 v-else class="text-color1 leading-normal mr-auto">
        {{ item.title }}
      </h5>

      <ButtonEl
        :text="buttonText"
        :is-loading="isLoading"
        :icon-right="true"
        icon="download"
        :disabled="isLoading"
        @click="generateAndDownloadReport(item.link)"
      />
    </div>
  </div>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconFileCopy from '@/assets/icons/custom/misc/IconFileCopy.vue';
import { axios } from '@/utils/axiosHelper';
import { ref } from 'vue';

const props = defineProps({
  data: {
    type: Array,
    default: null,
  },
  buttonText: {
    type: String,
    default: 'Download',
  },
});
const isLoading = ref(false);

async function generateAndDownloadReport(url) {
  isLoading.value = true;
  axios({
    method: 'GET',
    url,
    responseType: 'blob',
  })
    .then((res) => {
      const regExpFilename = /filename="(?<filename>.*)"/;
      const filename =
        regExpFilename.exec(res.headers['content-disposition'])?.groups
          ?.filename ?? null;
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename ?? 'Report.pptx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      isLoading.value = false;
    });
}
</script>

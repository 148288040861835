<template>
  <div class="flex justify-between">
    <SlideContainer
      position="left"
      title="Filter"
      :prevent-close-from-outside="modalIsOpen"
    >
      <template #activator="{ openModal }">
        <ButtonEl
          text="Filtereinstellung Default"
          icon="tune"
          @click="openModal"
        />
      </template>
      <EvaluationFilterWrapper
        @apply:filter="fetchResultData($event)"
        @update:modal-is-open="modalIsOpen = $event"
        @changed:filter-is-dirty="filterChanged = $event"
        @changed:filter-id="leadList.filterset = $event"
      />
    </SlideContainer>
    <div
      class="flex align-center text-title-neutral p-1 rounded-md standard-elevation-0-dark"
    >
      <IconWrapper icon="source" class="ml-2.5 mr-2" />
      <p class="caption-1 mt-1">
        Die Datenbasis für die hier angezeigten Informationen wird regelmäßig
        aktualisert
      </p>
      <p
        class="flex align-center pt-1 subtitle-4 text-neutral h-10 px-2.5 bg-subtle rounded-md ml-2.5"
      >
        Letzte Aktualisierung: 01.01.2025
      </p>
    </div>
    <ButtonEl
      :disabled="
        !userStore.uc4CanAddLeadList ||
        filterChanged ||
        leadList.filterset === null
      "
      text="Leadliste erstellen"
      color="success"
      icon="playlist_play"
      :icon-right="true"
      @click="showLeadlistCreate = true"
    />
    <VuetifyDialog
      v-if="showLeadlistCreate"
      v-model="showLeadlistCreate"
      title="Leadliste erstellen"
    >
      <template #content>
        <FormValidator v-model="leadlistInputsValid">
          <InputEl
            v-model="leadList.title"
            label="Name"
            class="mb-2.5"
            :rules="{ required: true }"
          />
          <InputEl
            v-model="leadList.description"
            label="Notiz"
            class="mb-7"
            :rules="{ required: true }"
          />
        </FormValidator>
      </template>
      <template #actions>
        <div class="flex justify-between w-full">
          <ButtonEl
            text="Abbrechen"
            color="color2"
            @click="showLeadlistCreate = false"
          />
          <ButtonEl
            text="Erstellen"
            :disabled="!leadlistInputsValid"
            @click="createLeadList()"
          />
        </div>
      </template>
    </VuetifyDialog>
  </div>
  <VExpansionPanels
    v-model="expanded1"
    :eager="true"
    variant="accordion"
    rounded="lg"
  >
    <VExpansionPanel>
      <VExpansionPanelTitle class="hover:bg-subtle">
        <h3 class="text-title-color1">Vertriebskennzahlen</h3>
      </VExpansionPanelTitle>
      <VExpansionPanelText>
        <div class="grid grid-cols-3 gap-5">
          <div class="w-full standard-elevation-0-dark rounded-md">
            <div
              v-for="column in cardsColumn1"
              :key="column"
              class="space-y-[5px]"
            >
              <DetailBox
                v-for="item in column"
                :key="item"
                v-bind="item"
                tool-tip-at-end
                :shadow="null"
                :minimum-digits="0"
                :maximum-digits="0"
              >
                <template #tooltip>
                  <PercentageCircle :percentage="item.percentage" />
                </template>
              </DetailBox>
            </div>
            <p class="body-1 text-disabled-neutral px-3 mt-3">
              Bezieht sich jeweils auf ein Gebäude
            </p>
          </div>
          <div
            v-for="column in cardsColumn2"
            :key="column"
            class="space-y-[5px] w-full"
          >
            <DetailBox
              v-for="item in column"
              :key="item"
              v-bind="item"
              tool-tip-at-end
              :minimum-digits="item.decimals"
              :maximum-digits="item.decimals"
            >
              <template #tooltip>
                <PercentageCircle :percentage="item.percentage" />
              </template>
            </DetailBox>
          </div>
          <div
            v-for="column in cardsColumn3"
            :key="column"
            class="space-y-[5px] w-full"
          >
            <DetailBox
              v-for="item in column"
              :key="item.label"
              v-bind="item"
              :minimum-digits="0"
              :maximum-digits="0"
              tool-tip-at-end
            >
              <template #tooltip>
                <PercentageCircle :percentage="item.percentage" />
              </template>
            </DetailBox>
          </div>
        </div>
      </VExpansionPanelText>
    </VExpansionPanel>
  </VExpansionPanels>
  <VExpansionPanels
    v-model="expanded2"
    :eager="true"
    variant="accordion"
    rounded="lg"
  >
    <VExpansionPanel>
      <VExpansionPanelTitle class="hover:bg-subtle">
        <h3 class="text-title-color1">Strukturübersicht</h3>
      </VExpansionPanelTitle>
      <VExpansionPanelText>
        <div class="grid grid-cols-4 h-[400px] gap-2.5">
          <BasicPieChart
            class="h-full"
            :chart-data="pieYearData"
            title="Gebäudetyp"
            :custom-radius="['35%', '55%']"
            :series-position="['50%', '36%']"
            legend-orient="horizontal"
            chart-unit="Gebäude"
            break-unit
            legend-top="255px"
            :show-label="false"
            custom-title-tag="h5"
            custom-title-classes="min-h-9"
            custom-wrapper-padding="pb-5"
            :legend-line-break="false"
          />
          <BasicPieChart
            class="h-full"
            :chart-data="pieTypeData"
            :title="'Gebäude nach\n Baualtersklassen'"
            :custom-radius="['35%', '55%']"
            :series-position="['50%', '36%']"
            legend-orient="horizontal"
            chart-unit="Gebäude"
            break-unit
            legend-top="255px"
            :show-label="false"
            custom-title-tag="h5"
            custom-title-classes="min-h-9"
            custom-wrapper-padding="pb-5"
            :legend-line-break="false"
          />
          <BasicPieChart
            class="w-[300px] h-full"
            :chart-data="pieSectorData"
            :title="'Gebäude nach\n Sektor'"
            :custom-radius="['35%', '55%']"
            :series-position="['50%', '36%']"
            legend-orient="horizontal"
            chart-unit="Gebäude"
            break-unit
            legend-top="255px"
            :show-label="false"
            custom-title-tag="h5"
            custom-title-classes="min-h-9"
            custom-wrapper-padding="pb-5"
            :legend-line-break="false"
          />
          <BasicPieChart
            class="w-[300px] h-full"
            :chart-data="pieSourceData"
            :title="'Gebäude nach\n Energieträger heute'"
            :custom-radius="['35%', '55%']"
            :series-position="['50%', '36%']"
            legend-orient="horizontal"
            chart-unit="Gebäude"
            break-unit
            legend-top="255px"
            :show-label="false"
            custom-title-tag="h5"
            custom-title-classes="min-h-9"
            custom-wrapper-padding="pb-5"
            :legend-line-break="false"
          />
        </div>
        <div class="w-full h-32 pr-5">
          <BarChartVertical
            :series="verticalStackedBarChartData"
            :show-x-axis-line="false"
            :show-x-axis-label="false"
            :show-y-axis="false"
            :show-tooltip-axis-pointer="false"
            :labels="['']"
            title="Gebäude nach Bestandskunden"
          />
        </div>
      </VExpansionPanelText>
    </VExpansionPanel>
  </VExpansionPanels>
  <VExpansionPanels
    v-model="expanded3"
    :eager="true"
    variant="accordion"
    rounded="lg"
  >
    <VExpansionPanel>
      <VExpansionPanelTitle class="hover:bg-subtle">
        <h3 class="text-title-color1">Wärmepumpenpotenzial</h3>
      </VExpansionPanelTitle>
      <VExpansionPanelText>
        <div class="flex justify-between w-full mb-9">
          <DropDown
            v-model="selectedColoring"
            label="Einfärbung der Balken nach"
            placeholder="Keine Einfärbung"
            class="w-[320px]"
            :items-data="colorings"
            items-data-key="label"
          />
          <DropDown
            v-model="selectedUnit"
            label="Einheit"
            placeholder="Keine Einfärbung"
            class="w-[250px]"
            :items-data="units"
            items-data-key="label"
          />
        </div>
        <div>
          <BarChartBasic
            :stacks="heatDensityData"
            title="Wärmeliniendichte"
            :x-axis-data="heatDensityThresholds"
            :y-axis-name="unitSetting.unit"
            :chart-unit="unitSetting.unit"
            grid-left="4%"
            class="min-h-[500px] mb-7"
            chart-classes="h-full w-full"
            :show-legend="false"
            bar-width="32"
          />
          <BarChartBasic
            :stacks="heatPumpData"
            title="Wärmepumpenampel"
            :x-axis-data="heatPumpThresholds"
            :y-axis-name="unitSetting.unit"
            :chart-unit="unitSetting.unit"
            grid-left="4%"
            class="min-h-[500px] mb-7"
            chart-classes="h-full w-full"
            :show-legend="false"
            bar-width="32"
          />
          <BarChartBasic
            :stacks="ownershipData"
            title="Eigentümerquote"
            :x-axis-data="ownershipThresholds"
            :y-axis-name="unitSetting.unit"
            :chart-unit="unitSetting.unit"
            grid-left="4%"
            class="min-h-[500px]"
            chart-classes="h-full w-full"
            :show-legend="false"
            bar-width="32"
          />
          <BarChartBasic
            :stacks="demographyData"
            title="Demografie"
            :x-axis-data="demographyThresholds"
            :y-axis-name="unitSetting.unit"
            :chart-unit="unitSetting.unit"
            grid-left="4%"
            class="min-h-[500px]"
            chart-classes="h-full w-full"
            :show-legend="true"
            bar-width="32"
          />
        </div>
      </VExpansionPanelText>
    </VExpansionPanel>
  </VExpansionPanels>
  <VExpansionPanels
    v-model="expanded4"
    :eager="true"
    variant="accordion"
    rounded="lg"
  >
    <VExpansionPanel>
      <VExpansionPanelTitle class="hover:bg-subtle">
        <h3 class="text-title-color1">
          Wärmepumpenpotenzial Top 10 Postleitzahlen
        </h3>
      </VExpansionPanelTitle>
      <VExpansionPanelText>
        <div class="flex justify-between gap-5 z-50">
          <DropDown
            v-model="selectedColoringTop10"
            label="Einfärbung der Balken nach"
            placeholder="Keine Einfärbung"
            class="w-[320px]"
            :items-data="colorings"
            items-data-key="label"
          />
          <DropDown
            v-model="selectedUnitPostalCode"
            label="Einheit"
            :initial-selection="true"
            class="w-[250px]"
            :items-data="units"
            items-data-key="label"
          />
        </div>

        <div class="w-full h-[400px] pr-5 relative">
          <BarChartVertical
            :series="top10PostalCodeData"
            :show-y-axis-line="false"
            :show-tooltip-axis-pointer="false"
            :labels="top10postalCodesNames"
            grid-bottom="10%"
          />
        </div>
      </VExpansionPanelText>
    </VExpansionPanel>
  </VExpansionPanels>
  <ButtonEl
    text="Quellenangaben"
    icon="info"
    variant="secondary"
    color="color2"
    class="ml-auto mt-auto"
    icon-type="round"
    :icon-right="true"
  />
</template>

<script setup>
import { toLocaleStr } from '@/utils/formatUtils';
import axios from '@/utils/axiosHelper';
import DetailBox from '@/apps/features/boxes/DetailBox.vue';
import IconHeatWavesCircleOutlined from '@/assets/icons/custom/energy-grid/IconHeatWavesCircleOutlined.vue';
import IconHeatWavesCircleFilled from '@/assets/icons/custom/energy-grid/IconHeatWavesCircleFilled.vue';
import BarChartVertical from '@/components/charts/BarChartVertical.vue';
import BarChartBasic from '@/components/charts/BarChartBasic.vue';
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import { computed, ref, watch } from 'vue';
import {
  AQUAMARIN_40,
  AZUR_BLUE_40,
  AZUR_BLUE_70,
  BUILDINGTYPE_BY_ENUM,
  CONSTRUCTION_YEAR_UC4_BY_ENUM,
  COSTUMER_AT_BY_ENUM,
  DEEP_BLUE_MEDIUM,
  HEATING_TYPE_BY_ENUM,
  SECTOR_BY_ENUM,
  WARM_GREY_20,
} from '@/configs/color-constants';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import PercentageCircle from '@/apps/usecase-4/evaluation/components/PercentageCircle.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import SlideContainer from '@/components/SlideContainer.vue';
import EvaluationFilterWrapper from './components/EvaluationFilterWrapper.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import InputEl from '@/components/input/InputEl.vue';
import FormValidator from '@/components/FormValidator.vue';
import { useUsers } from '@/apps/auth/users-store';
import { useToastStore } from '@/stores/toast-store';
import { LeadListDto } from '@/apps/usecase-4/evaluation/components/lead-list-dto';
import { restPost } from '@/utils/rest-utils';

const { showToast } = useToastStore();
const userStore = useUsers();
const props = defineProps({
  salesRegionId: {
    type: String,
    required: true,
  },
});

const expanded1 = ref(0);
const expanded2 = ref(0);
const expanded3 = ref(0);
const expanded4 = ref(0);

// ----------- Fetch data
async function fetchResultData(filter) {
  const dataResp = await axios({
    method: 'GET',
    url: `/api/usecase-4/results/${props.salesRegionId}`,
    params: filter,
  });
  resultData.value = dataResp.data;
  fullRegion.value = dataResp.data.full_sales_region_aggregation;
}

const resultData = ref();
const fullRegion = ref();

await fetchResultData({});

// -------------- setting items
const units = ref([
  {
    label: 'Anzahl Gebäude',
    value: 'buildings',
  },
  {
    label: 'Wärmelast in MW(th)',
    value: 'heatLoad',
  },
  {
    label: 'Wärmebedarf in MWh(th)/a',
    value: 'heatDemand',
  },
]);

const colorings = ref([
  {
    label: 'Keine Einfärbung',
    value: 'none',
  },
  {
    label: 'Baualtersklassen',
    value: 'buildingClass',
  },
  {
    label: 'Sektor',
    value: 'sector',
  },
  {
    label: 'Gebäudetyp',
    value: 'buildingType',
  },
  {
    label: 'Energieträger heute',
    value: 'energySource',
  },
  {
    label: 'Bestandskunden',
    value: 'existingCustomers',
  },
]);

// ---------------- Info Boxes in Tab Vertriebskennzahlen -----------------
const cardsColumn1 = computed(() => [
  [
    {
      percentage:
        (resultData.value.count_costumers_enbw /
          fullRegion.value.count_costumers_enbw) *
        100,
      icon: 'handshake',
      label: 'Anzahl Bestandskunden EnBW',
      value: resultData.value.count_costumers_enbw,
    },
    {
      percentage:
        (resultData.value.count_costumers_partners /
          fullRegion.value.count_costumers_partners) *
        100,
      icon: 'supervised_user_circle',
      label: 'Anzahl Bestandskunden Partner',
      value: resultData.value.count_costumers_partners,
    },
    {
      percentage:
        (resultData.value.count_costumers_enbw_and_partners /
          fullRegion.value.count_costumers_enbw_and_partners) *
        100,
      icon: 'groups',
      label: ['Anzahl Bestandskunden EnBW &', 'Partner'],
      value: resultData.value.count_costumers_enbw_and_partners,
    },
  ],
]);

const cardsColumn2 = computed(() => [
  [
    {
      percentage:
        (resultData.value.count_buildings / fullRegion.value.count_buildings) *
        100,
      icon: 'maps_home_work',
      label: 'Anzahl Gebäude',
      value: resultData.value.count_buildings,
      decimals: 0,
    },
    {
      percentage:
        (resultData.value.demand_kwh / fullRegion.value.demand_kwh) * 100,
      icon: IconHeatWavesCircleOutlined,
      label: 'Wärmebedarf',
      value: resultData.value.demand_kwh * 0.001,
      decimals: 0,
      unit: 'MWh(el)/a',
    },
    {
      percentage:
        (resultData.value.demand_kw / fullRegion.value.demand_kw) * 100,
      icon: IconHeatWavesCircleFilled,
      label: 'Wärmelast',
      value: resultData.value.demand_kw * 0.001,
      unit: 'MW(th)',
      decimals: 0,
    },
    {
      percentage:
        (resultData.value.commodity_potential_eur_a /
          fullRegion.value.commodity_potential_eur_a) *
        100,
      icon: 'inventory_2',
      label: 'Commodity-Potenzial',
      value: resultData.value.commodity_potential_eur_a,
      decimals: 2,
      unit: 'Mio. €/a',
    },
  ],
]);

const cardsColumn3 = computed(() => [
  [
    {
      percentage:
        (resultData.value.count_residents / fullRegion.value.count_residents) *
        100,
      icon: 'groups',
      label: 'Einwohner',
      value: resultData.value.count_residents,
    },
    {
      percentage:
        (resultData.value.count_municipalities /
          fullRegion.value.count_municipalities) *
        100,
      icon: 'push_pin',
      label: 'Anzahl Gemeinden',
      value: resultData.value.count_municipalities,
    },
    {
      percentage:
        (resultData.value.count_counties / fullRegion.value.count_counties) *
        100,
      icon: 'push_pin',
      label: 'Anzahl Landkreise',
      value: resultData.value.count_counties,
    },
    {
      percentage:
        (resultData.value.resident_density_persons_m2 /
          fullRegion.value.resident_density_persons_m2) *
        100,
      icon: 'portrait',
      label: 'Einwohnerdichte',
      value: resultData.value.resident_density_persons_m2 * 0.000001,
      unit: 'EW/km²',
    },
  ],
]);

// ----- Tab Strukturübersicht ------
const getPieData = (data, dataKey, style) => {
  return data.map((e) => {
    const choice = style[e[dataKey]];
    return {
      value: e.count_buildings,
      color: choice.color,
      name: choice.label,
    };
  });
};

const pieYearData = computed(() =>
  getPieData(
    resultData.value.by_construction_year_class,
    'construction_year_class_uc4',
    CONSTRUCTION_YEAR_UC4_BY_ENUM,
  ),
);
const pieTypeData = computed(() =>
  getPieData(
    resultData.value.by_building_type,
    'building_type',
    BUILDINGTYPE_BY_ENUM,
  ),
);
const pieSectorData = computed(() =>
  getPieData(resultData.value.by_sectors, 'sector', SECTOR_BY_ENUM),
);
const pieSourceData = computed(() =>
  getPieData(
    resultData.value.by_heating_type,
    'heating_type',
    HEATING_TYPE_BY_ENUM,
  ),
);

const verticalStackedBarChartData = computed(() => [
  {
    name: 'EnBW',
    type: 'bar',
    stack: 'Total',
    barWidth: '20px',
    emphasis: {
      focus: 'none',
    },
    data: [resultData.value.count_costumers_enbw],
    itemStyle: {
      color: DEEP_BLUE_MEDIUM,
    },
  },
  {
    name: 'Partner',
    type: 'bar',
    stack: 'Total',
    barWidth: '20px',
    emphasis: {
      focus: 'none',
    },
    data: [resultData.value.count_costumers_partners],
    itemStyle: {
      color: AZUR_BLUE_40,
    },
  },
  {
    name: 'EnBW & Partner',
    type: 'bar',
    stack: 'Total',
    barWidth: '20px',
    emphasis: {
      focus: 'none',
    },
    data: [resultData.value.count_costumers_enbw_and_partners],
    itemStyle: {
      color: AQUAMARIN_40,
    },
  },
  {
    name: 'Sonstige',
    type: 'bar',
    stack: 'Total',
    barWidth: '20px',
    emphasis: {
      focus: 'none',
    },
    data: [
      resultData.value.count_buildings -
        (resultData.value.count_costumers_enbw +
          resultData.value.count_costumers_partners +
          resultData.value.count_costumers_enbw_and_partners),
    ],
    itemStyle: {
      color: WARM_GREY_20,
    },
  },
]);

// ---------------- Wärmepumpenpotenzial Top 10 Kommunen -----------------
const selectedColoringTop10 = ref(null);
const selectedUnitPostalCode = ref('buildings');

const unitSettingPostalCode = computed(() => {
  if (selectedUnitPostalCode.value === 'buildings')
    return { dataKey: 'count_buildings', unit: 'Gebäude' };
  if (selectedUnitPostalCode.value === 'heatLoad')
    return { dataKey: 'demand_kw', unit: 'MW(th)' };
  if (selectedUnitPostalCode.value === 'heatDemand')
    return { dataKey: 'demand_kwh', unit: 'MWh(th)/a' };
  throw Error('unit in dropdown must be set');
});

const top10PostalCodes = computed(() => {
  return [...resultData.value.histogram_postal_codes]
    .sort((a, b) => {
      const key = unitSettingPostalCode.value.dataKey;
      return a[key] - b[key];
    })
    .slice(-10);
});

const top10postalCodesNames = computed(() => {
  return top10PostalCodes.value.map((e) => e.postal_code);
});

const top10PostalCodeData = computed(() => {
  return getHistogramData(
    top10PostalCodes.value,
    unitSettingPostalCode.value.dataKey,
    selectedColoringTop10.value,
    'Postleitzahl',
  );
});
// ----------------- HISTOGRAM

const selectedColoring = ref('none');
const selectedUnit = ref('buildings');

function getHistogramData(data, unit, selectedGroupKey, simpleSeriesName) {
  const multiplier = ['demand_kw', 'demand_kwh'].includes(unit) ? 0.001 : 1;
  const mappings = {
    buildingClass: {
      accessor: (item) => item.by_construction_year_class,
      enumMap: CONSTRUCTION_YEAR_UC4_BY_ENUM,
      key: 'construction_year_class_uc4',
    },
    sector: {
      accessor: (item) => item.by_sectors,
      enumMap: SECTOR_BY_ENUM,
      key: 'sector',
    },
    buildingType: {
      accessor: (item) => item.by_building_type,
      enumMap: BUILDINGTYPE_BY_ENUM,
      key: 'building_type',
    },
    energySource: {
      accessor: (item) => item.by_heating_type,
      enumMap: HEATING_TYPE_BY_ENUM,
      key: 'heating_type',
    },
    existingCustomers: {
      accessor: (item) => item.by_costumers,
      enumMap: COSTUMER_AT_BY_ENUM,
      key: 'existing_customer_at',
    },
  };
  const selectedMapping = mappings[selectedGroupKey];
  if (selectedMapping) {
    const { accessor, enumMap, key } = selectedMapping;
    const classes = accessor(data[0]).map((e) => e[key]);
    return classes.map((e, index) => ({
      name: enumMap[e].label,
      stack: 'total',
      itemStyle: { color: enumMap[e].color },
      data: data.map((c) => accessor(c)[index][unit] * multiplier),
      type: 'bar',
    }));
  }
  return [
    {
      name: simpleSeriesName,
      itemStyle: { color: AZUR_BLUE_70 },
      data: data.map((e) => e[unit] * multiplier),
      type: 'bar',
    },
  ];
}

const unitSetting = computed(() => {
  if (selectedUnit.value === 'buildings')
    return { dataKey: 'count_buildings', unit: 'Gebäude' };
  if (selectedUnit.value === 'heatLoad')
    return { dataKey: 'demand_kw', unit: 'MW(th)' };
  if (selectedUnit.value === 'heatDemand')
    return { dataKey: 'demand_kwh', unit: 'MWh(th)/a' };
  throw Error('unit in dropdown must be set');
});

const setAxisBins = (
  dataSet,
  dataKey,
  unit = '',
  unknown = 'unknown',
  multiplier = 1,
) => {
  const data = resultData.value[dataSet];

  return data.map((entry, index) => {
    const currentValue = data[index][dataKey];
    if (currentValue === unknown) {
      return 'Unbekannt';
    }
    const start = index > 0 ? data[index][dataKey] * multiplier + 1 : 0;
    const nextValue = index + 1 < data.length ? data[index + 1][dataKey] : null;
    const end = nextValue !== unknown ? nextValue * multiplier : null;
    return end
      ? `${toLocaleStr(start)}${unit} bis ${toLocaleStr(end)}${unit}`
      : `über ${toLocaleStr(start - 1)}${unit}`;
  });
};
const heatDensityThresholds = computed(() =>
  setAxisBins('histogram_heat_density', 'heat_density'),
);
const heatDensityData = computed(() => {
  const unit = unitSetting.value.dataKey;
  return getHistogramData(
    resultData.value.histogram_heat_density,
    unit,
    selectedColoring.value,
    'Wärmeliniendichte',
  );
});
const ampelTranslate = {
  green: 'Grün',
  yellow: 'Gelb',
  red: 'Rot',
  unk: 'Unbekannt',
};
const heatPumpThresholds = computed(() =>
  resultData.value.histogram_ampel.map((e) => ampelTranslate[e.ampel]),
);
const heatPumpData = computed(() => {
  const unit = unitSetting.value.dataKey;
  return getHistogramData(
    resultData.value.histogram_ampel,
    unit,
    selectedColoring.value,
    'Ampel',
  );
});

const ownershipThresholds = computed(() =>
  setAxisBins(
    'histogram_ownership_rate',
    'ownership_rate',
    '%',
    'unknown',
    100,
  ),
);
const ownershipData = computed(() => {
  const unit = unitSetting.value.dataKey;
  return getHistogramData(
    resultData.value.histogram_ownership_rate,
    unit,
    selectedColoring.value,
    'Eigentümerquote Wohngebäude',
  );
});
const demographyThresholds = computed(() =>
  setAxisBins('histogram_age', 'age'),
);
const demographyData = computed(() => {
  const unit = unitSetting.value.dataKey;
  return getHistogramData(
    resultData.value.histogram_age,
    unit,
    selectedColoring.value,
    'Demografie',
  );
});
// ------- filter
const modalIsOpen = ref(false);
const filterChanged = ref(false);

watch(filterChanged, (newVal) => {
  if (newVal === true) {
    showToast({
      color: 'warning',
      message:
        'Sie haben die Filtereinstellung geändert. Um eine Leadliste' +
        ' erstellen zu können, müssen Sie die Filtereinstellung ' +
        'speichern, oder den Filter auf seinen Gespeicherten Stand zurücksetzen',
    });
  }
});
// ------- lead list
// TODO: Disable save if leadlist for this filer and region exists
const showLeadlistCreate = ref(false);
const leadlistInputsValid = ref(false);
const leadList = ref(new LeadListDto());
leadList.value.sales_region = props.salesRegionId;
async function createLeadList() {
  await restPost(leadList.value, [
    'title',
    'description',
    'sales_region',
    'filterset',
  ]);
}
</script>

import {
  LAYER_KEY__ESTATE,
  LAYER_KEY__HIGH_VOLTAGE_GRID,
  LAYER_KEY__RESTRICTION,
  LAYER_KEY__GRID_STATIONS_SUBSTATION,
  LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION,
} from '@/apps/features/map/layer-config/base-data';
import { LAYER_KEY__EXTERNAL_PROJECT } from '@/apps/usecase-2/map/layer-config/external-project';
import {
  LAYER_KEY__PV_PROJECT,
  LAYER_KEY__PV_PROJECT_CONNECTION,
  LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
  LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
  LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
} from '@/apps/usecase-2/map/layer-config/potential-analysis';
import { LAYER_KEY__BUILDINGS } from '@/apps/features/map/layer-config/building';
import {
  ENVIRONMENT__LAYER_KEYS,
  LAYER_KEY__ALTITUDE,
  LAYER_KEY__GLOBAL_RADIATION,
  LAYER_KEY__ORIENTATION,
  LAYER_KEY__SLOPE,
} from '@/apps/features/map/layer-config/environment';
import {
  LAYER_KEY__AGRICULTURAL_PLOT,
  LAYER_KEY__DISADVANTAGE_AREA,
} from '@/apps/features/map/layer-config/area-qualification';
import { LAYER_KEY__ROAD } from '@/apps/features/map/layer-config/road';
import {
  LAYER_KEY__GENERATION_PV_FREE,
  LAYER_KEY__GENERATION_WIND,
} from '@/apps/features/map/layer-config/existing-power-plants';
import {
  LAYER_KEY__COUNTY,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
} from '@/apps/features/map/layer-config/administrativ-levels';
import { LAYER_KEY__PLANNING_MAP_PV_FREE } from '@/apps/features/map/layer-config/planning-map-pv-free';
import {
  LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT,
  LAYER_KEY__CLUSTER_PV_PROJECT,
  LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION,
  LAYER_KEY__CLUSTER_EXTERNAL_PROJECT,
} from '@/apps/usecase-2/map/layer-config/cluster';
import { LAYER_KEY__NETWORK_AREA } from '@/apps/features/map/layer-config/network-area';
import IconTrafo from '@/assets/icons/custom/energy-grid/IconTrafo.vue';
import { shallowRef } from 'vue';

export const layerPanelConfig = {
  sections: [
    {
      title: 'Basisdaten',
      icon: 'source',
      groups: [
        {
          title: 'Gebäudestruktur',
          id: 'buildingStructure',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                },
              },
              layerState: 'default',
            },
            {
              title: 'Sektor',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                },
              },
              layerState: 'sector',
            },
            {
              title: 'Baualtersklasse',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__BUILDINGS,
                },
              },
              layerState: 'constructionYearClass',
            },
          ],
        },
        {
          title: 'Administrative Grenzen',
          id: 'administativeLevels',
          items: [
            {
              title: 'Stadtteil',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__DISTRICT,
                },
              },
              layerState: 'default',
            },
            {
              title: 'Gemeinde',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__MUNICIPALITY,
                },
              },
              layerState: 'default',
            },
            {
              title: 'Landkreis',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__COUNTY,
                },
              },
              layerState: 'default',
            },
            {
              title: 'Regierungsbezirk',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__GOVERNMENT_DISTRICT,
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Flurstücke',
          id: 'estateStructure',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__ESTATE,
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Umweltdaten',
          id: 'environmentalData',
          items: [
            {
              title: 'Globalstrahlung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__GLOBAL_RADIATION,
                  unselect: ENVIRONMENT__LAYER_KEYS,
                },
              },
              layerState: 'default',
            },
            {
              title: 'Höhe',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__ALTITUDE,
                  unselect: ENVIRONMENT__LAYER_KEYS,
                },
              },
              layerState: 'default',
            },
            {
              title: 'Geländeneigung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__SLOPE,
                  unselect: ENVIRONMENT__LAYER_KEYS,
                },
              },
              layerState: 'default',
            },
            {
              title: 'Geländeausrichtung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__ORIENTATION,
                  unselect: ENVIRONMENT__LAYER_KEYS,
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Restriktionsflächen',
          id: 'restrictions',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__RESTRICTION,
                },
                restrictionFilterToggle: {
                  icon: 'filter_alt',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Besondere Flächenqualifikation',
          id: 'areaQuality',
          items: [
            {
              title: 'Benachteiligte Flächen',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__DISADVANTAGE_AREA,
                },
              },
              layerState: 'default',
            },
            {
              title: 'Landwirtschaftliche Nutzung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__AGRICULTURAL_PLOT,
                },
              },
              layerState: 'default',
            },
          ],
        },
        // {
        //   title: 'Landnutzung',
        //   id: 'landUse',
        //   items: [
        //     {
        //       title: 'Keine Einfärbung',
        //       itemClass: 'toggle',
        //       aggregation: {
        //         includeCustomToggle: {
        //           onLayer: '',
        //         },
        //       },
        //       layerState: 'default',
        //     },
        //   ],
        // },
        {
          title: 'Straßen und Wege',
          id: 'roads',
          items: [
            {
              title: 'Oberfläche',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__ROAD,
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Planhinweiskarte',
          id: 'planMap',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__PLANNING_MAP_PV_FREE,
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Netzgebiete',
          id: 'networkArea',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__NETWORK_AREA,
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Bestandsanlagen',
          id: 'existingPowerPlant',
          items: [
            {
              title: 'PV-Freifläche',
              id: 'generationPvFree',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_PV_FREE,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Betriebsstatus',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_PV_FREE,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_PV_FREE,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'generationPvFreeKw',
                },
              ],
            },
            {
              title: 'Wind',
              id: 'generationWind',
              items: [
                {
                  title: 'Keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_WIND,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Betriebsstatus',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_WIND,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'status',
                },
                {
                  title: 'Leistung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GENERATION_WIND,
                      icon: 'pin_drop',
                    },
                  },
                  layerState: 'generationWindKw',
                },
              ],
            },
          ],
        },
        {
          title: 'Externe Projekte',
          id: 'externalProjects',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__EXTERNAL_PROJECT,
                  icon: 'shape_line',
                  dataFromStoreAction: 'fetchAndCacheExternalProjects',
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Infrastruktur',
      icon: shallowRef(IconTrafo),
      groups: [
        {
          title: 'Mittelspannung ',
          id: 'mediumVoltage',
          items: [
            {
              title: 'Netzverknüpfungspunkte ',
              id: 'mediumVoltageGrid',
              items: [
                {
                  title: 'keine Einfärbung',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'default',
                },
                {
                  title: 'Freie Kapazität',
                  itemClass: 'toggle',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'maxPowerKw',
                },
                {
                  itemClass: 'toggle',
                  title: 'Netzbetreiber',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GRID_STATIONS_NETWORK_CONNECTION,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'operator',
                },
              ],
            },
            {
              title: 'Umspannwerke',
              id: 'infrastructureGridStation',
              items: [
                {
                  itemClass: 'toggle',
                  title: 'Keine Einfärbung',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GRID_STATIONS_SUBSTATION,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'default',
                },
                {
                  itemClass: 'toggle',
                  title: 'Freie Kapazität',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GRID_STATIONS_SUBSTATION,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'maxPowerKw',
                },
                {
                  itemClass: 'toggle',
                  title: 'Netzbetreiber',
                  aggregation: {
                    includeCustomToggle: {
                      onLayer: LAYER_KEY__GRID_STATIONS_SUBSTATION,
                      icon: 'shape_line',
                    },
                  },
                  layerState: 'operator',
                },
              ],
            },
          ],
        },
        {
          title: 'Hochspannungsnetz',
          id: 'infrastructureGrid',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__HIGH_VOLTAGE_GRID,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
            {
              title: 'Leitungstyp',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__HIGH_VOLTAGE_GRID,
                  icon: 'shape_line',
                },
              },
              layerState: 'ltype',
            },
          ],
        },
        // {
        //   title: 'Netzausbau',
        //   id: 'gridPlanning',
        //   items: [
        //     {
        //       title: 'keine Einfärbung',
        //       itemClass: 'toggle',
        //       aggregation: {
        //         includeCustomToggle: {
        //           onLayer: '',
        //           icon: 'shape_line',
        //         },
        //       },
        //       layerState: 'default',
        //     },
        //   ],
        // },
      ],
      show: true,
    },
    {
      title: 'Potenzialanalyse - Einzelprojekte',
      icon: 'all_out',
      active: true,
      LayerShortCut: [
        LAYER_KEY__PV_PROJECT,
        LAYER_KEY__PV_PROJECT_CONNECTION,
        LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
        LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
        LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
      ],
      groups: [
        {
          title: 'Potenzialfläche',
          id: 'potentialArea',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__PV_PROJECT,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
            {
              title: 'Flächengröße',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__PV_PROJECT,
                  icon: 'shape_line',
                },
              },
              layerState: 'area',
            },
            {
              title: 'Leistung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__PV_PROJECT,
                  icon: 'shape_line',
                },
              },
              layerState: 'power',
            },
            {
              title: 'Investitionsvolumen',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__PV_PROJECT,
                  icon: 'shape_line',
                },
              },
              layerState: 'invest',
            },
          ],
        },
        {
          title: 'Netzanbindung',
          id: 'grid',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__PV_PROJECT_CONNECTION,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Netzanschlusspunkt',
          id: 'gridConnection',
          items: [
            {
              title: 'Netzverknüpfungspunkt',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
            {
              title: 'Umspannwerk',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
            {
              title: 'HS-Anschluss',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Potenzialanalyse - Cluster',
      icon: 'bubble_chart',
      active: false,
      LayerShortCut: [
        LAYER_KEY__CLUSTER_PV_PROJECT,
        LAYER_KEY__CLUSTER_EXTERNAL_PROJECT,
        LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION,
        LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT,
      ],
      groups: [
        {
          title: 'Potenzialfläche ',
          id: 'clusterArea',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__CLUSTER_PV_PROJECT,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
            {
              title: 'Flächengröße',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__CLUSTER_PV_PROJECT,
                  icon: 'shape_line',
                },
              },
              layerState: 'area',
            },
            {
              title: 'Leistung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__CLUSTER_PV_PROJECT,
                  icon: 'shape_line',
                },
              },
              layerState: 'power',
            },
            {
              title: 'Investitionsvolumen',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__CLUSTER_PV_PROJECT,
                  icon: 'shape_line',
                },
              },
              layerState: 'invest',
            },
          ],
        },
        {
          title: 'Externe Projekte ',
          id: 'clusterExternalProjects',
          items: [
            {
              title: 'Externe Projekte ',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__CLUSTER_EXTERNAL_PROJECT,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Netzanbindung ',
          id: 'clusterGrid',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Netzanschlusspunkt ',
          id: 'clusterHvGridConnection',
          items: [
            {
              title: 'HS-Anschluss',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT,
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
  ],
};

<!-- eslint-disable vue/valid-v-slot -->
<template>
  <Component
    :is="totalItems !== null ? 'VDataTableServer' : 'VDataTable'"
    ref="vuetifyDataTable"
    v-model:items-per-page="itemsPerPage"
    v-model:expanded="expanded"
    :headers
    class="vuetify-data-table-custom rounded-lg standard-elevation-0-dark"
    :class="dataTableClasses"
    :items-length="totalItems"
    :items="itemData"
    :show-expand="showExpand"
    :item-value="itemValue"
    :page="page"
    :height="tableHeightFixedHeader"
    :fixed-header="fixedHeader"
    :loading="loading"
    :search="search"
    density="comfortable"
    :show-select="showSelect || showRadio"
    :expand-on-click="expandOnRowClick"
    :items-per-page="itemsPerPage"
    @click:row="clickRow"
    @update:options="setTableOptions"
  >
    <!-- dynamic template slots-->
    <template v-for="header in headers" #[`item.${header.key}`]="{ item }">
      <slot :name="header.key" :item="item">
        <template
          v-if="
            typeof item[header.key] === 'number' && isFinite(item[header.key])
          "
        >
          {{
            toLocaleStr(
              item[header.key] * (header.multiplier || 1),
              header.minDigits || 0,
              header.maxDigits || 0,
            ) + (header.unit ? ' ' + header.unit : '')
          }}
        </template>
        <template v-else-if="item[header.key] !== false">
          {{ item[header.key] }}
        </template>
      </slot>
    </template>

    <!-- expanded row template slot-->
    <template #expanded-row="{ columns, item }">
      <tr
        :class="[
          {
            [expandedBackground]: expandedBackground,
          },
          'expanded-content inner-shadow-1',
        ]"
      >
        <td :colspan="columns.length">
          <slot name="expanded-row" :item="item" />
        </td>
      </tr>
    </template>

    <!-- select/unselect all-->
    <template #header.data-table-select>
      <VCheckbox
        v-if="showSelect"
        v-model="selectAll"
        :indeterminate="checkedItems.length ? selectAll === false : null"
        indeterminate-icon="mdi-minus"
        :ripple="false"
        density="compact"
        :disabled="readOnly"
        class="ml-2"
        :class="{
          'intermediate-checkbox': checkedItems.length && !selectAll,
        }"
        :hide-details="true"
      />
    </template>

    <!-- select row -->
    <template #item.data-table-select="{ item }">
      <VCheckbox
        v-if="showSelect"
        v-model="checkedItems"
        :ripple="false"
        density="compact"
        :disabled="readOnly"
        :hide-details="true"
        content-class="elevation-0"
        :value="item[itemValue]"
      />
      <VRadioGroup v-if="showRadio" v-model="selectedRadio">
        <VRadio
          :value="item[itemValue]"
          @change="emit('selectedRadio', selectedRadio)"
        ></VRadio>
      </VRadioGroup>
    </template>

    <!--  icons open/close expand-row  -->
    <template #item.data-table-expand="{ item }">
      <div
        class="flex justify-center"
        data-test="open-expandable"
        @click="expandOnRowClick ? null : expandRow(item)"
      >
        <IconWrapper
          :icon="item[itemValue] === expanded[0] ? 'remove' : 'add'"
          :size="32"
          fill="text-core-color1"
          hover="hover:text-button-primary-hover-color1"
          class="cursor-pointer mr-5"
        />
      </div>
    </template>

    <!-- pagination-->
    <template #bottom>
      <div
        v-if="showPaginationComputed"
        :class="{ 'pointer-events-none': !itemData.length }"
        class="flex justify-end items-center gap-[10px] p-[10px] vuetify-data-table-footer"
      >
        <div class="flex gap-2 items-center w-fit">
          <div class="subtitle-2 pt-[1px] text-neutral">Zeige:</div>
          <DropDown
            v-model="itemsPerPage"
            :items-data="perPageOptionsFiltered"
            :clearable="false"
            max-height-dropdown="100%"
            :disabled="
              perPageOptions.length === 1 || perPageOptions.length === 0
            "
            :open-to-top="openPageOptionsToTop"
          />
        </div>

        <div class="body-2 pt-0.5 text-neutral min-w-fit text-right">
          {{ startRecord }} - {{ endRecord }}
          <span> von {{ totalRecords }}</span>
        </div>

        <!-- pagination arrows-->
        <div class="flex">
          <ButtonEl
            icon="west"
            icon-type="round"
            color="color2"
            :disabled="page === 1"
            @click="page--"
          />
          <ButtonEl
            :disabled="
              page * itemsPerPage >=
              (totalItems !== null ? totalItems : itemData.length)
            "
            icon="east"
            icon-type="round"
            color="color2"
            @click="page++"
          />
        </div>
      </div>
    </template>
  </Component>
</template>

<script setup>
import { useWindowSize } from '@vueuse/core';
import { computed, onBeforeMount, ref, watch, watchEffect } from 'vue';
import DropDown from '../DropDown/DropDown.vue';
import IconWrapper from '../IconWrapper/IconWrapper.vue';
import ButtonEl from '../button/ButtonEl.vue';
import { toLocaleStr } from '@/utils/formatUtils';

const props = defineProps({
  headers: {
    type: Array,
    default: () => [],
  },
  itemData: {
    type: Array,
    default: () => [],
  },
  showPagination: {
    type: Boolean,
    default: true,
  },
  itemValue: {
    type: String,
    default: 'id',
  },
  expandOnRowClick: {
    type: Boolean,
    default: false,
  },
  showExpand: {
    type: Boolean,
    default: true,
  },
  cursorPointerOnRow: {
    type: Boolean,
    default: false,
  },
  perPageOptions: {
    type: Array,
    default: () => [10, 20, 50],
  },
  openPageOptionsToTop: {
    type: Boolean,
    default: true,
  },
  fixedHeader: {
    type: Boolean,
    default: false,
  },
  substractHeight: {
    type: Number,
    default: 358,
  },
  substractHeightWindow: {
    type: Number,
    default: 420,
  },
  resetExpansion: {
    type: Boolean,
    default: false,
  },
  showSelect: {
    type: Boolean,
    default: false,
  },
  showRadio: {
    type: Boolean,
    default: false,
  },
  search: {
    type: String,
    default: null,
  },
  totalItems: {
    type: Number,
    default: null,
  },
  setExpanded: {
    type: Array,
    default: () => [],
  },
  expandedBackground: {
    type: String,
    default: 'bg-default',
  },
  selectAllCheckboxes: {
    type: Boolean,
    default: false,
  },
  headerSmall: {
    type: Boolean,
    default: false,
  },
  showBackground: {
    type: Boolean,
    default: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  fixedRowHeight: {
    type: Number,
    default: null,
  },
  fixedHeaderHeight: {
    type: Number,
    default: null,
  },
  setPage: {
    type: Number,
    default: 1,
  },
  setItemsPerPage: {
    type: Number,
    default: 10,
  },
  setLoading: {
    type: Boolean,
    default: false,
  },
  preventImmediateUpdateOptions: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'expanded',
  'clickedRowData',
  'selectedRows',
  'selectedRadio',
  'update:options',
]);

const vuetifyDataTable = ref(null);
const page = ref(props.setPage);
const itemsPerPage = ref(props.setItemsPerPage);
const expanded = ref([]);
const loading = ref(props.setLoading);
const checkedItems = ref([]);
const selectedRadio = ref(null);

watchEffect(() => {
  loading.value = props.setLoading;
});

const dataTableClasses = computed(() => {
  return {
    'no-cursor-pointer': !props.expandOnRowClick,
    'header-small': props.headerSmall,
    'no-background': !props.showBackground,
    'hide-border-table-wrapper': showPaginationComputed.value,
  };
});
const selectAll = computed({
  get() {
    return checkedItems.value.length === props.itemData.length;
  },
  set(val) {
    checkedItems.value = val
      ? props.itemData.map((item) => item[props.itemValue])
      : [];
  },
});

const { height: windowHeight } = useWindowSize();

const tableHeightFixedHeader = computed(() => {
  // Ensure the ref is set and the underlying DOM element is accessible
  const dataTableElement = vuetifyDataTable.value?.$el;
  if (!dataTableElement) return null; // Return early if the ref is not available

  // Get the tbody element within the data table
  const tbodyElement = dataTableElement.querySelector('tbody');
  if (!tbodyElement) return null; // Return early if the tbody is not available

  // Get the height of the tbody
  const tbodyHeight = tbodyElement.clientHeight;

  // Determine if the height adjustment is needed
  const shouldAdjustHeight =
    tbodyHeight >= windowHeight.value - props.substractHeightWindow;

  // Return the appropriate height style if the header should be fixed
  return shouldAdjustHeight && props.fixedHeader
    ? `calc(100vh - ${props.substractHeight}px)`
    : null;
});

const showPaginationComputed = computed(() => {
  // Don't show pagination if hideFooter is true
  if (props.hideFooter) return false;

  return true;
});

const perPageOptionsFiltered = computed(() => {
  const totalCount =
    props.totalItems !== null ? props.totalItems : props.itemData.length;
  // Cap totalCount at 100
  const cappedTotalCount = Math.min(totalCount, 100);

  // Create options with capped total count and filter existing options
  const options = [
    cappedTotalCount,
    ...props.perPageOptions.filter((option) => option <= totalCount),
  ];

  // Sort options from low to high and ensure unique options
  return [...new Set(options)].sort((a, b) => a - b);
});

const startRecord = computed(() => {
  return (page.value - 1) * itemsPerPage.value + 1;
});

const endRecord = computed(() => {
  const calculatedEnd = page.value * itemsPerPage.value;
  return calculatedEnd > totalRecords.value
    ? totalRecords.value
    : calculatedEnd;
});

const totalRecords = computed(() => {
  return props.totalItems !== null ? props.totalItems : props.itemData.length;
});

function clickRow(item, row) {
  emit('clickedRowData', row);
}

function expandRow(item) {
  if (expanded.value.includes(item[props.itemValue])) {
    expanded.value = [];
  } else {
    expanded.value = [item[props.itemValue]];
  }
}

function setTableOptions(data) {
  ({ page: page.value, itemsPerPage: itemsPerPage.value } = data);
}

function updateCheckedItemsOnItemDataChange(bigger) {
  if (bigger) {
    // Check all Items
    checkedItems.value = props.itemData.map((item) => item[props.itemValue]);
  } else {
    // For performance reasons create Object (needed for big data)
    const itemDataObj = props.itemData.reduce((result, item) => {
      return { ...result, [item[props.itemValue]]: true };
    }, {});
    // Keep unchecked Items unchecked
    checkedItems.value = checkedItems.value.filter((item) => itemDataObj[item]);
  }
}

onBeforeMount(() => {
  if (props.selectAllCheckboxes) {
    checkedItems.value = props.itemData.map((item) => item[props.itemValue]);
  }

  if (props.itemData.length === 1) {
    selectedRadio.value = props.itemData[0][props.itemValue];
    emit('selectedRadio', selectedRadio.value);
  }
});

watch(
  [page, itemsPerPage],
  () => {
    const data = {
      page: page.value,
      itemsPerPage: itemsPerPage.value,
    };
    emit('update:options', data);

    if (props.totalItems) {
      loading.value = true;
    }
  },
  { deep: true, immediate: !props.preventImmediateUpdateOptions },
);

watch(
  () => checkedItems.value,
  (val) => {
    emit('selectedRows', val);
  },
);

watch(
  () => expanded.value,
  (val) => {
    if (val.length > 1) {
      val.shift();
    }
    emit('expanded', val);
  },
);

watch(
  () => page.value,
  () => {
    expanded.value = [];
    props.totalItems && (checkedItems.value = []);
  },
);

watch(
  () => props.itemData,
  (newVal, oldVal) => {
    if (!props.showPagination) {
      itemsPerPage.value = newVal.length;
    }
    if (props.totalItems) {
      loading.value = false;
    }
    updateCheckedItemsOnItemDataChange(newVal > oldVal);
  },
  { deep: true, immediate: true },
);

watch(
  () => itemsPerPage.value,
  () => {
    page.value = 1;
    checkedItems.value = [];
  },
);

watch(
  () => props.resetExpansion,
  () => {
    expanded.value = [];
  },
);

watch(
  () => props.setExpanded,
  (val) => {
    expanded.value = val;
  },
);

watch(
  () => props.setPage,
  (newVal) => {
    page.value = newVal;
  },
);

watch(
  () => props.setItemsPerPage,
  (newVal) => {
    itemsPerPage.value = newVal;
  },
);
</script>

<style lang="scss">
@use '@/assets/styles';

.vuetify-data-table-custom {
  position: relative;
  height: 100%;

  .v-data-table-progress {
    display: none;
  }

  .v-table__wrapper {
    @extend .hide-scrollbar;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  &.hide-border-table-wrapper {
    .v-table__wrapper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.header-small {
    .v-data-table-header__content {
      @apply py-5;
    }
  }

  thead th {
    padding: 0px !important;
    @apply bg-white;
    .v-data-table-header__content {
      @apply text-title-neutral py-5 whitespace-pre;
      @extend h5;
      display: flex;
      align-items: center;
      height: v-bind(
        'props.fixedHeaderHeight ? `${props.fixedHeaderHeight}px` : "auto"'
      );
      overflow: hidden;
      > span {
        &:not(:empty) {
          padding-left: 16px !important;
          padding-right: 16px !important;
        }
      }
    }
  }

  th,
  tr:not(.expanded-content) td {
    border: none !important;
    overflow: hidden;
  }

  tbody {
    td {
      @extend .body-2;
      @apply text-core-dark;
    }

    .v-data-table__td:not(.v-data-table__th) {
      padding: 16px !important;
      white-space: nowrap;
      &:empty {
        padding: 0px !important;
      }
      height: v-bind(
        'props.fixedRowHeight ? `${props.fixedRowHeight}px` : "auto"'
      );
      overflow: hidden;
      vertical-align: middle;
    }

    tr:nth-of-type(odd) {
      @apply bg-subtle;
    }

    tr:nth-of-type(even) {
      @apply bg-default;
    }
  }

  &.no-cursor-pointer tr {
    cursor: default;
  }

  &.no-background {
    tbody tr {
      background-color: transparent;

      &:first-child {
        @apply border-y;
      }

      &:not(:first-child) {
        @apply border-b;
      }
    }
  }
}

// Styling for table checkboxes
.mdi-checkbox-marked {
  @apply text-color1;
}
</style>

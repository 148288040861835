<template>
  <div class="w-full h-screen relative flex">
    <LayerPanel
      v-if="loaded && !mapStore.searchResults"
      v-show="mapStore.showMainMenu"
      :map-store
    >
    </LayerPanel>
    <div class="mapView h-full relative w-full">
      <template v-if="loaded">
        <BaseMap
          :map-store
          :selected-analysis-id="selectedSalesRegionId"
          :layer-config="LayerConfig"
          :panel-objects="panelObjects"
          :fly-to-function="flyToBBox"
          @map-style-loaded-signal="onMapStyleLoad()"
        />
        <div class="top-controls absolute w-full">
          <ControlBar :map-store />
          <AnalysisSelector
            v-model="selectedLeadListId"
            :items="leadLists"
            :map-store
            :item-height-virtual-list="97"
          >
            <template #title-selected>
              <div class="caption-1 whitespace-nowrap flex">Lead-Listen</div>
              <h5 class="whitespace-nowrap max-w-60 truncate">
                {{ selectedLeadListName }}
              </h5>
            </template>
            <template #title-icon="{ item, index, hoverIndex }">
              <Transition name="fade">
                <IconWrapper
                  v-if="
                    hoverIndex === index || selectedLeadListId === item.data.id
                  "
                  :icon="IconGotoChart"
                  fill="text-color1"
                  hover="hover:text-button-primary-hover-color1"
                  class="cursor-pointer"
                  @click="navigateToDetail(item.data.id)"
                />
              </Transition>
            </template>
          </AnalysisSelector>
          <LegendControls
            :map-store
            :show-legend="showLegend"
            @toggle-legend-state="showLegend = !showLegend"
          />
        </div>
        <div class="bottom-controls absolute w-full">
          <MapControls :map-store v-bind="$attrs" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { onBeforeUnmount, onMounted, ref, watch, computed } from 'vue';
import LayerPanel from '@/apps/features/map/layer-panel/LayerPanel.vue';
import LayerConfig from '@/apps/usecase-4/map/layer-config';
import BaseMap from '@/apps/features/map/BaseMap.vue';
import ControlBar from '@/apps/features/map/control-options/components/ControlBar.vue';
import LegendControls from '@/apps/features/map/control-options/components/LegendControls.vue';
import MapControls from '@/apps/features/map/control-options/components/MapControls.vue';
import AggregationToggle from '@/apps/usecase-4/map/layer-panel/AggregationToggle.vue';
import { useMapStore } from './map-store';
import LayerPanelAggregationLevels from '@/apps/features/map/layer-panel/input-forms/LayerPanelAggregationLevels.vue';
import AnalysisSelector from '@/apps/features/map/control-options/components/AnalysisSelector.vue';
import { panelObjects } from '@/apps/usecase-4/map/info-panel/Info-panel-objects';
import axios from '@/utils/axiosHelper';
import { fitBBox } from '@/mapbox/main';
import { LAYER_KEY__BUILDINGS } from '@/apps/usecase-4/map/layer-config/buildings';
import {
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
} from '@/apps/usecase-4/map/layer-config/geographical-level';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import IconGotoChart from '@/assets/icons/custom/misc/IconGotoChart.vue';
import { restFetch } from '@/utils/rest-utils';
import { SalesRegionDto } from '@/apps/usecase-4/sales-regions/sales-region-dto';

const mapStore = useMapStore();

const route = useRoute();
const router = useRouter();

const showLegend = ref(false);
const loaded = ref(false);

onMounted(async () => {
  initializeMap();
});

function initializeMap() {
  mapStore.resetConfigs();
  animateControls();
  loaded.value = true;
}

function onMapStyleLoad() {
  fetchAndFilterLeadList();
}

onBeforeUnmount(() => {
  mapStore.showMainMenu = false;
});

function flyToBBox() {
  return axios({
    method: 'GET',
    url: `/api/usecase-4/initial-map-bbox/${selectedSalesRegionId.value}/`,
  }).then((resp) => {
    const bbox = resp.data.bbox;
    fitBBox(bbox);
  });
}

function animateControls() {
  setTimeout(() => {
    const bottomControls = document.querySelector('.bottom-controls');
    bottomControls.classList.add('bottom-controls-active');
    const topControls = document.querySelector('.top-controls');
    topControls.classList.add('top-controls-active');
  }, 500);
}

const selectedSalesRegionId = ref(parseInt(route.params.salesRegionId));
const salesRegion = await restFetch(
  SalesRegionDto,
  selectedSalesRegionId.value,
);

const leadLists = computed(() => [
  { id: null, title: 'Keine' },
  ...salesRegion.lead_lists,
]);
const selectedLeadList = ref();
const selectedLeadListId = ref(parseInt(route.params.leadListId) || null);
const selectedLeadListName = computed(() => {
  if (selectedLeadListId.value === null) return 'Keine';
  return leadLists.value.find((e) => e.id === selectedLeadListId.value).title;
});

watch(selectedLeadListId, async (newLeadListId) => {
  await router.push({
    name: 'mapView4',
    params: { leadListId: newLeadListId },
  });
  await fetchAndFilterLeadList();
});

async function fetchAndFilterLeadList() {
  if (selectedLeadListId.value) {
    const leadListData = await axios({
      method: 'GET',
      url: `/api/usecase-4/leadlist/${selectedLeadListId.value}/`,
    });
    selectedLeadList.value = leadListData.data;
  } else {
    selectedLeadList.value = null;
  }
  filterLeadList();
}

function filterLeadList() {
  let filter = null;
  if (selectedLeadList.value !== null) {
    const buildingIds = selectedLeadList.value.buildings;
    filter = ['in', ['get', 'id'], ['literal', buildingIds]];
  }
  console.log(filter);
  mapStore.addFilter([LAYER_KEY__BUILDINGS], filter, 'leadListFilter');
}

function navigateToDetail(salesRegionId) {
  router.push({
    name: 'Usecase4SalesRegionEvaluationOverview',
    params: { salesRegionId },
  });
}
</script>

<style lang="scss" scoped>
.bottom-controls {
  bottom: -100px;
  transition: bottom 1s ease;
}

.bottom-controls-active {
  bottom: 0;
}

.top-controls {
  top: -100px;
  transition: top 1s ease;
}

.top-controls-active {
  top: 20px;
}
</style>

export const tabs = [
  {
    title: 'Name & Gebietsauswahl',
    icon: 'zoom_in_map',
    iconType: 'outlined',
  },
  {
    title: 'Restriktionen',
    icon: 'deselect',
    iconType: 'outlined',
  },
  {
    title: 'Flächenkulisse',
    icon: 'settings_applications',
    iconType: 'outlined',
  },
  {
    title: 'Bes. Flächenqualifizierung',
    icon: 'layers',
    iconType: 'outlined',
  },
  {
    title: 'PV-Auslegung',
    icon: 'settings_applications',
    iconType: 'outlined',
  },
  {
    title: 'Netzanschluss',
    icon: 'settings_applications',
    iconType: 'outlined',
  },
];

export const tableHeadersPotentials = [
  {
    title: 'Name',
    key: 'title',
    align: 'start',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    align: 'start',
  },
  {
    title: 'Aktualisiert am',
    key: 'updated_at',
    align: 'start',
  },
  {
    title: 'Analysetyp',
    key: 'is_type',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Status',
    key: 'progress',
    sortable: false,
    align: 'start',
  },
  {
    title: '',
    key: 'functions',
    align: 'end',
    width: '150px',
    sortable: false,
  },
];

import IconUrbaneStandorte from '@/assets/icons/custom/misc/IconUrbaneStandorte.vue';
import IconZahnrad from '@/assets/icons/custom/misc/IconZahnrad.vue';
import IconVertriebsgebiete from '@/assets/icons/custom/misc/IconVertriebsgebiete.vue';

const useCase4CardData = [
  {
    title: 'Vertriebsgebiete',
    id: 'evaluation',
    description:
      'Anlegen und Auswertung von Projektgebieten.' +
      'Von der Übersicht des Vertriebspotenzials für Wärmepumpen, bis hin zum Export adressscharfer Lead-Listen.',
    icon: IconVertriebsgebiete,
    row: 1,
    order: 1,
    routeName: 'SalesRegionsList',
  },
  {
    title: 'Kartenansicht',
    id: 'mapView4',
    description:
      'Geografische Verortung und Auswertung der Projektanalysen in der Kartenansicht.',
    icon: IconUrbaneStandorte,
    row: 1,
    order: 2,
    routeName: 'mapSalesRegionSelection',
  },
  {
    title: 'Allgemeine Annahmen',
    id: 'assumptions-general',
    description:
      'Zentrale Einstellung aller projektübergreifenden Annahmen und Parameter.',
    icon: IconZahnrad,
    row: 2,
    order: 1,
    routeName: 'Assumptions',
  },
];

export { useCase4CardData };

//<path d="M82 32.0059V24C82 22.8965 81.1025 22 80 22H56C54.8975 22 54 22.8965 54 24V36.666L50 42H80C80.7041 42 81.3438 41.6406 81.7109 41.0371C82.2832 40.0976 83.5254 39.7656 84.4678 40.3301C85.4102 40.8965 85.7393 42.082 85.1797 43.0293L85.1631 43.0566C84.0273 44.9238 82.1113 46 80 46H42L50 35.334V24C50 20.6914 52.6914 18 56 18H80C83.3086 18 86 20.6914 86 24V32.0059C86 33.1113 85.1045 34.0059 84 34.0059C82.8955 34.0059 82 33.1113 82 32.0059ZM70 34V30H66V34H70ZM62 34V30H58V34H62ZM78 34V30H74V34H78ZM16 34C16 27.3828 21.3828 22 28 22C34.6172 22 40 27.3828 40 34C40 40.6172 34.6172 46 28 46C21.3828 46 16 40.6172 16 34ZM20 34C20 38.4121 23.5889 42 28 42C32.4111 42 36 38.4121 36 34C36 29.5879 32.4111 26 28 26C23.5889 26 20 29.5879 20 34ZM28 50C19.1777 50 12 57.1777 12 66V78C12 79.1055 12.8955 80 14 80C15.1045 80 16 79.1055 16 78V66C16 59.3828 21.3828 54 28 54C34.6172 54 40 59.3828 40 66V78C40 79.1055 40.8955 80 42 80C43.1045 80 44 79.1055 44 78V66C44 57.1777 36.8223 50 28 50Z" fill="#FE8F11"/>

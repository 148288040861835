<template>
  <div v-for="row in cards" :key="row" class="flex space-x-2.5">
    <DetailBox
      v-for="item in row"
      :key="item.label"
      :minimum-digits="0"
      :maximum-digits="0"
      v-bind="item"
    />
  </div>
  <div class="flex w-full justify-between standard-elevation-0-dark rounded-md">
    <BasicPieChart
      v-for="chart in pieCharts"
      :key="chart.title"
      :chart-data="chart.data"
      :title="chart.title"
      chart-unit="Gebäude"
      title-inside="Gebäude"
      :show-chart-unit-inside-title="false"
      class="chart-container"
      :series-position="['50%', '40%']"
      :custom-radius="['40%', '60%']"
      :legend-top="chart.legendTop"
      legend-orient="horizontal"
      :show-label="false"
    />
  </div>
  <div class="standard-elevation-0-dark rounded-md p-5">
    <h3 class="text-title-color1 mb-5">Übersicht Gemeinde und PLZ</h3>
    <FormTabs :tabs="tabs" :prevent-window-scroll="true">
      <template #slot0>
        <VuetifyDataTable
          :item-data="getPlzSorted()"
          :headers="plzHeaders"
          :show-expand="false"
          :fixed-header="true"
          style="min-height: calc(100vh - 290px)"
        />
      </template>
      <template #slot1>
        <VuetifyDataTable
          :item-data="response.data.postal_codes"
          :headers="municipalityHeaders"
          :show-expand="false"
          :fixed-header="true"
          style="min-height: calc(100vh - 290px)"
        />
      </template>
    </FormTabs>
  </div>
</template>

<script setup>
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import DetailBox from '@/apps/features/boxes/DetailBox.vue';
import {
  BUILDINGTYPE_BY_ENUM,
  CONSTRUCTION_YEAR_UC4_BY_ENUM,
  HEATING_TYPE_BY_ENUM_UC4,
  SECTOR_BY_ENUM_UC_4,
} from '@/configs/color-constants';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import axios from '@/utils/axiosHelper';
import { computed } from 'vue';
const props = defineProps({
  salesRegionId: {
    type: String,
    required: true,
  },
});
// ---------------- Cards section -----------------

const cards = computed(() => [
  [
    {
      icon: 'groups',
      label: 'Einwohner',
      value: response.data.count_residents,
    },
    {
      icon: 'home_work',
      label: 'Anzahl Gebäude',
      value: response.data.count_buildings,
    },
    {
      icon: 'settings_overscan',
      label: 'Fläche',
      value: response.data.area_m2,
      unit: 'km²',
    },
    {
      icon: 'portrait',
      label: 'Einwohnerdichte',
      value: response.data.resident_density_persons_m2,
      unit: 'EW/km²',
    },
  ],
]);

// ---------------- Piecharts section -----------------

const response = await axios({
  method: 'GET',
  url: `/api/usecase-4/overview/${props.salesRegionId}`,
});

function getChartData(response, buildingEnum, key) {
  return response.reduce((result, current) => {
    current.color = buildingEnum[current[key]].color;
    current.name = buildingEnum[current[key]].label;
    current.value = current.count_buildings;
    result.push(current);

    return result;
  }, []);
}

const pieCharts = computed(() => {
  const byBuildingTypeData = {
    title: 'Gebäudetyp',
    legendTop: '270px',
  };
  byBuildingTypeData.data = getChartData(
    response.data.by_building_type,
    BUILDINGTYPE_BY_ENUM,
    'building_type',
  );

  const byBuildingYearClassData = {
    title: 'Gebäude nach Baualtersklassen',
    legendTop: '270px',
  };
  byBuildingYearClassData.data = getChartData(
    response.data.by_construction_year_class,
    CONSTRUCTION_YEAR_UC4_BY_ENUM,
    'construction_year_class_uc4',
  );

  const byBuildingSectorData = {
    title: 'Gebäude nach Sektor',
    legendTop: '270px',
  };
  byBuildingSectorData.data = getChartData(
    response.data.by_sector,
    SECTOR_BY_ENUM_UC_4,
    'sector',
  );

  const byBuildingHeatingTypeData = {
    title: 'Gebäude nach Energieträger heute',
    legendTop: '270px',
  };
  byBuildingHeatingTypeData.data = getChartData(
    response.data.by_heating_type,
    HEATING_TYPE_BY_ENUM_UC4,
    'heating_type',
  );

  return [
    byBuildingTypeData,
    byBuildingYearClassData,
    byBuildingSectorData,
    byBuildingHeatingTypeData,
  ];
});

// ---------------- Municipalities and PLZ section -----------------

const tabs = [
  {
    title: 'PLZ',
    icon: 'email',
  },
  {
    title: 'Gemeinden',
    icon: 'location_city',
  },
];

const municipalityHeaders = [
  {
    title: 'Gemeinde',
    key: 'municipality_name',
    width: '350px',
  },
  {
    title: 'Landkreis',
    key: 'county_name',
    width: '300px',
  },
  {
    title: 'Einwohner',
    key: 'count_residents',
    width: '150px',
    align: 'end',
  },
  {
    title: 'Anzahl Gebäude',
    key: 'count_buildings',
    width: '180px',
    align: 'end',
  },
  {
    title: 'Fläche',
    key: 'area_m2',
    width: '140px',
    align: 'end',
  },
  {
    title: 'Einwohnerdichte',
    key: 'resident_density_persons_m2',
    width: '180px',
    align: 'end',
  },
];

const plzHeaders = [
  {
    title: 'PLZ',
    key: 'code',
    width: '100px',
  },
  ...municipalityHeaders,
];

function getPlzSorted() {
  return response.data.postal_codes.toSorted((a, b) => {
    if (a.code < b.code) {
      return -1;
    } else if (a.code > b.code) {
      return 1;
    }
    return 0;
  });
}
</script>

<style scoped>
.chart-container {
  height: 400px;
  max-width: 330px;
}
</style>

<template>
  <TopHeaderBar>
    <InputSearch
      class="w-[565px]"
      placeholder="Nach Vertriebsgebiet oder Ersteller suchen"
      :search-keys="['title', 'created_by']"
      :items="salesRegions"
      @update:search="filteredSalesRegions = $event"
    />

    <ButtonEl
      v-if="userStore.uc4CanAddSalesRegion"
      icon="add"
      text="Neues Vertriebsgebiet"
      @click="router.push({ name: 'CreateSalesRegions' })"
    />
  </TopHeaderBar>

  <div class="rounded-[8px] overflow-hidden">
    <VuetifyDataTable
      :item-data="filteredSalesRegions"
      :headers="SalesRegionsListHeaders"
      class="project-list-table"
      :fixed-header="true"
      style="min-height: calc(100vh - 290px)"
      :show-expand="false"
    >
      <template #title="{ item }">
        <div class="flex align-center">
          <div class="flex flex-col gap-3 pr-5 pl-0">
            <h5>{{ item.title }}</h5>
            <span class="body-3">
              {{ formatTableItemsText(item, 'postalCodes') }}
            </span>
          </div>
        </div>
      </template>
      <template #created_by="{ item }">
        <div>
          {{ item.created_by }}
        </div>
      </template>
      <template #created_at="{ item }">
        <div>
          {{ new Date(item.created_at).toLocaleDateString('de-DE') }}
        </div>
      </template>
      <template #progress="{ item }">
        <div class="flex justify-center items-center pr-4">
          <IconWrapper
            v-if="
              [0, 2].some((el) => progressByVertriebsgebiet[item.id].has(el))
            "
            :icon="
              progressByVertriebsgebiet[item.id].has(0)
                ? 'task_alt'
                : IconTaskProgressSharp
            "
            :fill="
              progressByVertriebsgebiet[item.id].has(0)
                ? 'text-spot-success'
                : 'text-spot-warning'
            "
          />
          <ToolTip v-else tooltip-text="Something went wrong">
            <IconWrapper
              class="cursor-pointer"
              icon="error_outline"
              fill="text-red-600"
            />
          </ToolTip>
        </div>
      </template>
      <template #functions="{ item }">
        <div class="flex justify-start items-center gap-4">
          <DeleteDialog
            :id="item.id"
            :name-deleted-item="item.title"
            :dto="SalesRegionDto"
            :disabled="false"
            @instance-removed="salesRegionStore.fetchSalesRegions"
          >
            <IconWrapper
              v-if="userStore.uc4CanDeleteSalesRegion"
              :size="32"
              fill="text-core-color1"
              hover="hover:text-button-primary-hover-color1"
              icon="delete_forever"
              class="cursor-pointer"
              type="round"
            />
          </DeleteDialog>
          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="map"
            class="cursor-pointer"
            type="round"
            @click="navigateToMap(item.id)"
          />
          <IconWrapper
            :size="32"
            type="round"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="arrow_forward"
            :disabled="!progressByVertriebsgebiet[item.id].has(0)"
            class="cursor-pointer"
            @click="navigateToDetail(item.id)"
          />
        </div>
      </template>
    </VuetifyDataTable>
  </div>
</template>

<script setup>
import { useUsers } from '@/apps/auth/users-store';
import { headers as SalesRegionsListHeaders } from '@/apps/usecase-4/sales-regions/sales-regions-list-headers';
import { SalesRegionDto } from '@/apps/usecase-4/sales-regions/sales-region-dto';
import { useSalesRegionsStore } from '@/apps/usecase-4/sales-regions/sales-regions-store';
import IconTaskProgressSharp from '@/assets/icons/custom/misc/IconTaskProgressSharp.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const salesRegionStore = useSalesRegionsStore();
const userStore = useUsers();

await salesRegionStore.fetchSalesRegions();

const salesRegions = computed(() => salesRegionStore.salesRegions);
const filteredSalesRegions = ref(salesRegions.value);

watch(
  salesRegions,
  (newSalesRegions) => {
    filteredSalesRegions.value = newSalesRegions;
  },
  { immediate: true, deep: true },
);

function navigateToDetail(salesRegionId) {
  router.push({
    name: 'Usecase4SalesRegionEvaluationOverview',
    params: { salesRegionId },
  });
}

// computed property that returns all progresses of all projects as a set
const progressByVertriebsgebiet = computed(() => {
  const salesRegionsProgress = {};
  for (const salesRegion of salesRegions.value) {
    salesRegionsProgress[salesRegion.id] = new Set();
    salesRegionsProgress[salesRegion.id].add(0);
  }
  return salesRegionsProgress;
});

function navigateToMap(salesRegionId) {
  router.push({
    name: 'mapView4',
    params: { salesRegionId, leadListId: 'null' },
  });
}

function formatTableItemsText(item, type) {
  const data = {
    postalCodes: {
      count_id: 'count_postal_codes',
      label: 'PLZ',
      pluralLabel: 'PLZ',
    },
  };
  const { count_id, label, pluralLabel } = data[type];
  const count = item[count_id];
  return `${count.toLocaleString('de-DE')} ${count > 1 ? pluralLabel : label}`;
}
</script>

<style lang="scss">
.project-list-table thead th:first-child {
  padding-left: 19px !important;
}
</style>

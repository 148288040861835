import { SimplePanel } from '@/apps/features/map/info-panel/panel-data/simple-panel';
import { LAYER_KEY__BUILDINGS } from '../layer-config/buildings';
import {
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
} from '../layer-config/geographical-level';
import { LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS } from '../layer-config/infrastructure';

const urlPrefix = '/api/usecase-4';

interface PanelObjectParamters {
  url: string;
  layerKey: string;
  optionsUrl?: string;
  otherNestedKeys?: string[];
}

const panelObjectParamters: PanelObjectParamters[] = [
  {
    url: '/building/',
    layerKey: LAYER_KEY__BUILDINGS,
    otherNestedKeys: ['building'],
  },
  {
    url: '/result-aggregation/building_block',
    layerKey: LAYER_KEY__BLOCK,
  },
  {
    url: '/result-aggregation/district',
    layerKey: LAYER_KEY__DISTRICT,
  },
  {
    url: '/result-aggregation/municipality',
    layerKey: LAYER_KEY__MUNICIPALITY,
  },
  {
    url: '/result-aggregation/administrative_district',
    layerKey: LAYER_KEY__GOVERNMENT_DISTRICT,
  },
  {
    url: '/result-aggregation/county',
    layerKey: LAYER_KEY__COUNTY,
  },
  {
    url: '/result-aggregation/federal_state',
    layerKey: LAYER_KEY__FEDERAL_STATE,
  },
  {
    url: '/heat-segment-main-result',
    layerKey: LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS,
    optionsUrl: '/heat-segment-main-result-meta-data/',
  },
];

export const panelObjects = panelObjectParamters.map(
  ({ url, layerKey, otherNestedKeys = [] }) =>
    new SimplePanel(`${urlPrefix}${url}`, [layerKey], 'id', otherNestedKeys),
);

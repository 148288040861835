import IconGebaeudeInfrastruktur from '@/assets/icons/custom/misc/IconGebaeudeInfrastruktur.vue';
import IconInfrastrukturErzeugung from '@/assets/icons/custom/misc/IconInfrastrukturErzeugung.vue';
import IconKlimaneutraleEnBW from '@/assets/icons/custom/misc/IconKlimaneutraleEnBW.vue';
import { markRaw } from 'vue';

const rawCardData = [
  {
    title: 'Energiewendepartner für Landkreise und Kommunen',
    id: 0,
    description:
      'Als Energiewendepartner der Landkreise und Kommunen gestaltet die EnBW ' +
      'aktiv auf Basis eines datenbasierten und dynamischen Zielbildes des ' +
      'Energiesystems die Energiewende.',
    icon: markRaw(IconGebaeudeInfrastruktur),
    row: 1,
    order: 1,
    routeName: 'UseCase1',
    permission: {
      app: 'usecase_1',
      permission: 'view_usecase_1',
    },
  },
  {
    title: 'Optimierte Geoallokation von Erzeugungsanlagen',
    id: 1,
    description:
      'Identifizierung  von geeigneten Flächen für die Projektentwicklung von ' +
      'PV-Freiflächenanlagen unter Berücksichtigung von Netzdaten ' +
      '(u.a. freie Kapazitäten).',
    icon: markRaw(IconInfrastrukturErzeugung),
    row: 1,
    order: 2,
    routeName: 'UseCase2',
    permission: {
      app: 'usecase_2',
      permission: 'view_usecase_2',
    },
  },
  {
    title: 'Wärmepumpen-KundInnen für die vernetzte Energiewelt',
    id: 2,
    description:
      'Identifizierung potenzieller Wärmepumpen-Kund*innen für die EnBW und ' +
      'die Wärmepumpen-Partner auf Basis geodatenbasierter ' +
      'Wärmebedarfsanalytik.',
    icon: markRaw(IconKlimaneutraleEnBW),
    row: 1,
    order: 3,
    permission: {
      app: 'usecase_4',
      permission: 'view_usecase_4',
    },
    routeName: 'UseCase4',
  },
];

export { rawCardData };

import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { useBaseMapStore } from '@/apps/features/map/base-map-store';
import { layerPanelConfig as panelConfig } from '@/apps/usecase-4/map/layer-panel/layer-panel-config';
import layers from './layer-config';

import {
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
} from './layer-config/geographical-level';

const AGGREGATION_LAYERS = [
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
];

export const useMapStore = defineStore('MapUsecase4', {
  state: () => ({
    ...useBaseMapStore.state(),
    layerPanelConfig: cloneDeep(panelConfig),
    layerConfigs: cloneDeep(layers),
  }),
  getters: {
    ...useBaseMapStore.getters,
  },
  actions: {
    ...useBaseMapStore.actions,
    resetConfigs() {
      this.layerPanelConfig = cloneDeep(panelConfig);
      this.layerConfigs = cloneDeep(layers);
      this.activeLayerToggleSections = {};
    },
    setAggregationLevel(layer) {
      for (const layer of AGGREGATION_LAYERS) {
        this.layerConfigs[layer].visible = false;
      }
      if (layer) {
        this.layerConfigs[layer].visible = true;
        // set layer state accordingly
        this.changeLayerState({
          layerKeys: [layer],
          layerState: this.aggregationStyle,
        });
      } else {
        this.setAggregationStyle('default');
      }
      this.aggregationLevel = layer;
    },
  },
});

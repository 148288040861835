export const colorProjectStatus = (reviewState, text) => {
  const styleConfig = {
    color: '',
    bgColor: '',
    icon: '',
    text,
  };
  switch (reviewState) {
    case 'created':
      styleConfig.color = 'text-spot-warning';
      styleConfig.bgColor = 'hover:bg-fill-warning';
      styleConfig.icon = 'pending_actions';
      break;
    case 'in_progress':
      styleConfig.color = 'text-spot-warning';
      styleConfig.bgColor = 'hover:bg-fill-warning';
      styleConfig.icon = 'pending_actions';
      break;
    case 'complete':
      styleConfig.color = 'text-spot-success';
      styleConfig.bgColor = 'hover:bg-fill-success';
      styleConfig.icon = 'check';
      break;
    case 'termination':
      styleConfig.color = 'text-spot-error';
      styleConfig.bgColor = 'hover:bg-fill-error';
      styleConfig.icon = 'cancel';
      break;
  }
  return styleConfig;
};
export function getListProgressIcon(progress) {
  const progressMap = {
    finished: {
      icon: 'mark_email_read',
      fill: 'text-spot-success bg-default',
      label: 'Abgeschlossen',
    },
    sent: {
      icon: 'forward_to_inbox',
      fill: 'text-spot-warning bg-default',
      label: 'Versandt an Partner',
    },
    open: {
      icon: 'pending_actions',
      fill: 'text-spot-warning bg-default',
      label: 'Offen',
    },
  };

  return progressMap[progress];
}

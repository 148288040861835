<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="mapStore.showAnalysisSelector"
      :class="{ 'rounded-b-none': isExpanded }"
      class="bg-white standard-elevation-2 rounded-[8px] outer-container z-20"
    >
      <div class="relative w-full flex p-1.5 gap-2">
        <div class="flex rounded-[6px] w-full">
          <div
            ref="analysisWrapper"
            class="analysis-wrapper w-full"
            :class="{
              extended: isExpanded,
            }"
          >
            <div class="analysis-box flex gap-2">
              <slot name="before-title" />
              <div
                class="flex items-center w-full justify-between gap-1.5 standard-elevation-0-dark py-1 px-1.5 rounded-[6px] text-color1 cursor-pointer"
                @click="isExpanded = !isExpanded"
              >
                <div class="flex flex-col">
                  <slot name="title-selected" />
                </div>
                <IconWrapper
                  icon="keyboard_arrow_down"
                  :class="[
                    {
                      'rotate-180': isExpanded,
                    },
                    'transform duration-300',
                  ]"
                />
              </div>
              <ButtonEl
                icon="close"
                color="color2"
                :edges="true"
                class="w-10 h-10 rounded-[6px]"
                @click="mapStore.toggleAnalysisSelector"
              />
            </div>
            <div v-if="isExpanded" class="analysis-list-wrapper" @click.stop>
              <InputSearch
                class="rounded-[4px] px-1.5 overflow-hidden"
                placeholder="Suche"
                :search-keys="['title']"
                :items
                :prevent-close-on-click-outside="true"
                @update:search="searchResults = $event"
              />
              <div
                v-bind="containerProps"
                :style="{ maxHeight: maxHeight }"
                class="hide-scrollbar"
              >
                <VRadioGroup v-model="selected" v-bind="wrapperProps">
                  <div
                    v-for="(item, index) in analysesVirtualList"
                    :key="item.data.id"
                    class="rounded-sm px-1.5 py-[5px]"
                    @mouseenter="hoverIndex = index"
                    @mouseleave="hoverIndex = null"
                  >
                    <div
                      class="standard-elevation-0-dark p-2.5 flex flex-col items-start gap-1.5 hover:bg-active-area rounded-sm hover:border-white hover:border hover:p-[9px]"
                      :class="{
                        'bg-active-area border-white border p-[9px]':
                          item.data.id === selected,
                        'border-white border p-[9px]': hoverIndex === index,
                      }"
                    >
                      <div class="flex flex-col gap-1 w-full -ml-1 -mb-0.5">
                        <div class="flex items-center justify-between gap-1.5">
                          <VRadio
                            :value="item.data.id"
                            :density="null"
                            :disabled="disabledCallback(item.data)"
                          >
                            <template #label>
                              <h6
                                class="flex text-title-color1 hover:text-button-primary-hover-color1 mb-[1px]"
                              >
                                {{ item.data.title }}
                              </h6>
                            </template>
                          </VRadio>
                          <div class="-mr-1">
                            <slot
                              name="title-icon"
                              :item="item"
                              :index="index"
                              :hover-index="hoverIndex"
                            />
                          </div>
                        </div>
                      </div>
                      <slot
                        name="additional-info"
                        :item="item"
                        :index="index"
                        :hover-index
                      />
                    </div>
                  </div>
                </VRadioGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { ref, computed, watchEffect, watch } from 'vue';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import {
  onClickOutside,
  onKeyStroke,
  useVirtualList,
  useVModel,
  useWindowSize,
} from '@vueuse/core';

const { height: containerHeight } = useWindowSize();

const props = defineProps({
  items: Array,
  modelValue: Number,
  mapStore: {
    type: Object,
    required: true,
  },
  disabledCallback: {
    type: Function,
    default: (_) => false,
  },
  itemHeightVirtualList: {
    type: Number,
    default: 111,
  },
  maxItemsShow: {
    type: Number,
    default: 5,
  },
});

const emit = defineEmits(['update:expanded', 'update:modelValue']);
const selected = useVModel(props, 'modelValue', emit);

const isExpanded = ref(false);
const analysisWrapper = ref(null);
const hoverIndex = ref(null);
const searchResults = ref([]);

function closeSelector() {
  isExpanded.value = false;
  searchResults.value = [];
}

watch(selected, () => {
  closeSelector();
});

const filteredItems = computed(() => {
  if (!searchResults.value.length) return props.items;
  const searchResultIds = searchResults.value.map((item) => item.id);
  return props.items.filter((item) => searchResultIds.includes(item.id));
});

const {
  list: analysesVirtualList,
  containerProps,
  wrapperProps,
} = useVirtualList(filteredItems, {
  itemHeight: props.itemHeightVirtualList,
  overscan: 3,
});

onClickOutside(analysisWrapper, () => {
  closeSelector();
});

onKeyStroke('Escape', () => {
  closeSelector();
});

const maxHeight = computed(() => {
  const itemCount = props.items.length;
  const maxItems = Math.min(itemCount, props.maxItemsShow);
  const calculatedHeight = props.itemHeightVirtualList * maxItems;
  return containerHeight.value - 192 < calculatedHeight
    ? `${containerHeight.value - 192}px`
    : `${calculatedHeight}px`;
});

watchEffect(() => {
  if (isExpanded.value) {
    emit('update:expanded');
  }
});
</script>

<style scoped lang="scss">
@use '@/assets/styles';

.outer-container {
  @apply absolute left-1/2 -top-1 transform -translate-x-1/2 min-w-[250px];
  @media (max-width: 1020px) {
    @apply top-12;
  }
}

.analysis-wrapper {
  display: flex;
  flex-direction: column;
  @apply rounded-[15px];

  .analysis-list-wrapper {
    @apply mt-2 pt-0 rounded-b-[8px] flex flex-col gap-0.5 w-full
    left-0 top-[46px] z-10;

    @apply absolute bg-white;
    @extend .standard-elevation-1;

    &::before {
      @apply absolute left-0 top-[-3px] w-full h-[3px] bg-white;
      content: '';
    }

    .analysis-item {
      @apply bg-subtle rounded-[3px] flex items-center justify-start p-2 min-w-max h-8
      cursor-pointer text-neutral;
      @extend .body-2;
    }
  }
}
</style>

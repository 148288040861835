<template>
  <div class="flex flex-col gap-5">
    <TopHeaderBar go-back-to-custom="SalesRegionsList" />
    <div
      class="flex flex-col gap-7 bg-default rounded-[16px] p-5 standard-elevation-2 w-full h-full project-result-detail"
    >
      <div class="flex justify-between gap-5 items-center text-title-neutral">
        <EditTitle
          :dto="SalesRegionDto"
          :instance-id="salesRegion.id"
          :title="salesRegion.title"
          :unique-check-values="salesRegions"
          :disabled="!userStore.uc4CanChangeSalesRegion"
          @title-changed="salesRegion.title = $event"
        />
        <ButtonEl
          :text="reviewStateStyle.text"
          :custom-text-color="reviewStateStyle.color"
          :custom-bg-hover-color="reviewStateStyle.bgColor"
          :icon="reviewStateStyle.icon"
          variant="primary"
          :disabled="!userStore.uc4CanChangeSalesRegion"
          @click="showReviewStateDialog = true"
        />
      </div>
      <CardNavigation
        :tabs="navigationCards"
        :url-depth="3"
        @update-active-tab="activeTab = $event"
      />
      <RouterView v-slot="{ Component }" :key="route.fullPath" :sales-region-id>
        <Suspense>
          <component :is="Component" v-if="Component" />
          <template #fallback>
            <div class="w-full my-auto flex items-center justify-center">
              <LoadingSpinner />
            </div>
          </template>
        </Suspense>
      </RouterView>
    </div>
    <VuetifyDialog
      v-if="showReviewStateDialog"
      v-model="showReviewStateDialog"
      title="Status setzen"
      :persistent="true"
    >
      <template #content>
        <div class="flex flex-col gap-5">
          <VRadioGroup v-model="reviewState" class="-ml-1">
            <div>
              <VRadio
                v-for="radio in salesRegionMetaData['status'].choices"
                :key="radio.value"
                class="mt-1"
                :value="radio.value"
              >
                <template #label>
                  <h5 class="ml-1 pb-[1px] text-core-dark">
                    {{ radio.display_name }}
                  </h5>
                </template>
              </VRadio>
            </div>
          </VRadioGroup>

          <TextAreaEl v-model="description" label="Notiz" />
        </div>
      </template>
      <template #actions>
        <div class="flex justify-between w-full">
          <ButtonEl
            text="Abbrechen"
            color="color2"
            @click="showReviewStateDialog = false"
          />
          <ButtonEl
            text="Speichern"
            @click="patchProject({ status: reviewState, description })"
          />
        </div>
      </template>
    </VuetifyDialog>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { EvaluationTabs } from './selectable-categories';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import CardNavigation from '@/apps/navigation/CardNavigation.vue';
import { useRoute } from 'vue-router';
import LoadingSpinner from '@/components/loading-spinner/LoadingSpinner.vue';
import EditTitle from '@/components/EditTitle.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import TextAreaEl from '@/components/textAreaEl/TextAreaEl.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import { SalesRegionDto } from '@/apps/usecase-4/sales-regions/sales-region-dto';
import { useSalesRegionsStore } from '@/apps/usecase-4/sales-regions/sales-regions-store';
import { colorProjectStatus } from '@/apps/usecase-4/utils';
import {
  restFetch,
  restGetOrFetchMetaData,
  restPatch,
} from '@/utils/rest-utils';
import { useUsers } from '@/apps/auth/users-store';

const userStore = useUsers();

const route = useRoute();
const props = defineProps({
  salesRegionId: {
    type: String,
    required: true,
  },
});
const navigationCards = computed(() => {
  return EvaluationTabs.filter((e) => {
    const perm = e.requiresPerm;
    return !perm || userStore.hasPermission(perm.app, perm.permission);
  });
});
const salesRegionsStore = useSalesRegionsStore();
await salesRegionsStore.fetchSalesRegions();
const salesRegions = computed(() => salesRegionsStore.salesRegions);

const salesRegionMetaData = await restGetOrFetchMetaData(SalesRegionDto);
const salesRegion = ref(await restFetch(SalesRegionDto, props.salesRegionId));

const showReviewStateDialog = ref(false);
const reviewState = ref(salesRegion.value.status);
const reviewStateStyle = computed(() => {
  const reviewState = salesRegion.value.status;
  const text = salesRegionMetaData['status'].choices.find(
    (e) => e.value === reviewState,
  ).display_name;
  return colorProjectStatus(reviewState, text);
});

const description = ref(salesRegion.value.description);

function patchProject(args) {
  restPatch(SalesRegionDto, salesRegion.value.id, args).then(async () => {
    salesRegion.value = await restFetch(SalesRegionDto, props.salesRegionId);
    showReviewStateDialog.value = false;
  });
}
</script>

<style scoped>
.project-result-detail {
  min-height: calc(100vh - 290px);
}
</style>

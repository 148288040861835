export const colorGridPlanningStatus = (value) => {
  if (value?.includes('Netzanschluss eingeschränkt erfolgreich')) {
    return 'text-spot-warning bg-fill-warning';
  }

  if (value?.includes('Netzanschluss erfolgreich')) {
    return 'text-spot-success bg-fill-success';
  }

  return 'text-spot-error bg-fill-error';
};

export const colorProjectStatus = (reviewState, text) => {
  const styleConfig = {
    color: '',
    bgColor: '',
    icon: '',
    text,
  };
  switch (reviewState) {
    case 'TODO':
      styleConfig.color = 'text-spot-warning';
      styleConfig.bgColor = 'hover:bg-fill-warning';
      styleConfig.icon = 'pending_actions';
      break;
    case 'YES':
      styleConfig.color = 'text-spot-success';
      styleConfig.bgColor = 'hover:bg-fill-success';
      styleConfig.icon = 'check';
      break;
    case 'NO':
      styleConfig.color = 'text-spot-error';
      styleConfig.bgColor = 'hover:bg-fill-error';
      styleConfig.icon = 'cancel';
      break;
  }
  return styleConfig;
};

export function navigateToMap(
  router,
  analysisId,
  projectId = null,
  clusterId = null,
) {
  const option = {
    name: 'mapView2',
    params: { analysisId },
  };
  if (projectId) option.query = { projectId };
  if (clusterId) option.query = { clusterId };
  router.push(option);
}

export const valueToChoice = (value, option) => {
  if (typeof value === 'undefined' || value === null) {
    return '-';
  } else {
    try {
      return option.choices.find((e) => e.value === value).display_name;
    } catch (e) {
      throw Error(
        `choicefield ${value} not in meta options: ${option.choices}`,
      );
    }
  }
};

export const sortedAnalysesSelectableAsBase = (analyses) => {
  return [...analyses.filter((e) => e.is_quickcheck === false)].sort((a, b) =>
    a.title.localeCompare(b.title),
  );
};

export const getReviewStateStyle = (reviewState) => {
  const reviewStateMap = {
    TODO: {
      icon: 'build_circle',
      color: 'text-spot-warning',
      label: 'Prüfung ausstehend',
    },
    YES: {
      icon: 'check_circle',
      color: 'text-spot-success',
      label: 'Fortführung',
    },
    NO: {
      icon: 'cancel',
      color: 'text-spot-error',
      label: 'Abbruch/nicht relevant',
    },
  };

  return reviewStateMap[reviewState];
};

import { defineStore } from 'pinia';
import { restFetchAll } from '@/utils/rest-utils';
import { SalesRegionDto } from '@/apps/usecase-4/sales-regions/sales-region-dto';

export const useSalesRegionsStore = defineStore('salesRegions', {
  state: () => ({
    salesRegions: [],
  }),
  actions: {
    async fetchSalesRegions() {
      try {
        this.salesRegions = await restFetchAll(SalesRegionDto);
      } catch (error) {
        console.error('Error fetching sales regions:', error);
        throw error;
      }
    },
  },
});

<template>
  <ParameterSetView
    form-title="Allgemeine Annahmen"
    :header-icon="IconZahnrad"
    :url="'/api/usecase-4/assumptions/'"
    :release-option="false"
    :exclude-fields="['created_by']"
    :enable-release="false"
    :is-read-only="() => !userStore.uc4CanChangeAssumption"
    :table-headers="tableHeaders"
    :is-single-instance="true"
    :show-tabs="true"
    :content-structure="contentStructureAssumptionsGeneral"
  />
</template>

<script setup>
import ParameterSetView from '@/apps/features/parameter-set/ParameterSetView.vue';
import IconZahnrad from '@/assets/icons/custom/misc/IconZahnrad.vue';
import { useUsers } from '@/apps/auth/users-store';
import { contentStructureAssumptionsGeneral } from './contentStructureAssumptions';

const userStore = useUsers();

const tableHeaders = [
  {
    title: 'Erstellt am',
    key: 'date',
    sortable: false,
    width: '125px',
  },
  {
    title: 'Aktionen',
    key: 'functions',
    align: 'end',
    sortable: false,
    width: '125px',
  },
];
</script>

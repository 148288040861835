<template>
  <div ref="searchContainer" class="relative">
    <InputEl
      v-model="searchText"
      :label
      :placeholder
      :search-suggestions="searchSuggestions"
      :suffix-icon="true"
      suffix="search"
      suffix-color="text-neutral"
      :suffix-left="true"
      :disabled
      class="w-full"
      @update:model-value="highlightMatch($event)"
      @clicked:search-suggestion="emitSearchSuggestion"
    />
    <IconWrapper
      v-if="searchText"
      icon="close"
      fill="text-active-area"
      type="outlined"
      :size="22"
      class="absolute right-[10px] bottom-[-2px] translate-y-[-50%] cursor-pointer bg-core-color1 rounded-full"
      @click.stop="resetAndFocus"
    />
  </div>
</template>

<script setup>
import InputEl from '../../components/input/InputEl.vue';
import IconWrapper from '../../components/IconWrapper/IconWrapper.vue';
import { computed, ref, watch } from 'vue';
import FlexSearch from 'flexsearch';
import { onClickOutside } from '@vueuse/core';

const { Index } = FlexSearch;

const props = defineProps({
  placeholder: {
    type: String,
  },
  label: {
    type: String,
  },
  items: {
    type: Array,
    required: true,
    default: () => [],
  },
  searchKeys: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [String, Number, Object],
    default: null,
  },
  itemValue: {
    type: String,
    default: null,
  },
  preventCloseOnClickOutside: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:search', 'update:modelValue']);

const searchText = ref(null);
const itemsInner = ref([]);
const searchContainer = ref(null);
const index = ref(null);

onClickOutside(searchContainer, () => {
  if (!props.preventCloseOnClickOutside) {
    searchText.value = null;
  }
});

watch(
  () => props.items,
  (val) => {
    itemsInner.value = val;
    initializeSearch();
  },
  { deep: true, immediate: true },
);

function initializeSearch() {
  index.value = new Index({
    tokenize: 'forward',
    preset: 'score',
    resolution: 9,
    threshold: 0,
    depth: 4,
  });

  itemsInner.value.forEach((item, id) => {
    const searchableText = props.searchKeys
      .map((key) => String(item[key] || '').toLowerCase())
      .join(' ');
    index.value.add(id, searchableText);
  });
}

const searchResults = computed(() => {
  if (!searchText.value) {
    return itemsInner.value || [];
  }

  const searchTerm = searchText.value.toLowerCase().trim();
  const matchingIds = index.value.search(searchTerm);

  return itemsInner.value.filter((item, index) => {
    if (matchingIds.includes(index)) return true;

    return props.searchKeys.some((key) => {
      const value = String(item[key] || '').toLowerCase();
      return value.includes(searchTerm);
    });
  });
});

const searchSuggestions = computed(() => {
  return searchResults.value.map((item) =>
    props.searchKeys
      .map((key) => item[key])
      .filter(Boolean)
      .join(' / '),
  );
});

function highlightMatch(word) {
  const divs = searchContainer.value?.querySelectorAll('.search-item');
  divs?.forEach((div) => {
    div.innerHTML = div.textContent;

    if (word) {
      const regex = new RegExp(`(${word})`, 'gi');

      div.innerHTML = div.innerHTML.replace(
        regex,
        `<span class="bg-active-area">$1</span>`,
      );
    }
  });
}

function resetAndFocus() {
  searchText.value = null;
  emit('update:modelValue', null);
}

watch(searchText, () => {
  emit('update:search', searchResults.value);
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      // Find the item in props.items that matches the modelValue
      const selectedItem = props.items.find((item) => {
        if (typeof newValue === 'object') {
          // For objects, compare all properties
          return Object.keys(newValue).every(
            (key) => item[key] === newValue[key],
          );
        }
        // For string values, compare with the id field
        return String(item.id) === String(newValue);
      });

      if (selectedItem) {
        searchText.value = props.searchKeys
          .map((key) => selectedItem[key])
          .join(' / ');
      }
    } else {
      searchText.value = null;
    }
  },
  { immediate: true },
);

function emitSearchSuggestion(suggestion) {
  // Split the suggestion back into individual values
  const suggestionParts = suggestion.split(' / ');

  // Find the item that matches ALL search keys exactly
  const selectedItem = searchResults.value.find((item) => {
    return props.searchKeys.every((key, index) => {
      const itemValue = String(item[key] || '');
      const suggestionValue = String(suggestionParts[index] || '');
      return itemValue === suggestionValue;
    });
  });

  if (selectedItem) {
    searchText.value = suggestion;
    emit(
      'update:modelValue',
      props.itemValue ? selectedItem[props.itemValue] : selectedItem,
    );
  }
}
</script>

<template>
  <div
    ref="parameterSetForm"
    class="bg-white rounded-[8px] standard-elevation-0-dark p-5 flex flex-col gap-5 parameter-set-form"
  >
    <div
      v-if="!isSingleInstance"
      class="flex items-center justify-between gap-5"
    >
      <h3 v-if="formTitle" class="text-title-neutral">{{ formTitle }}</h3>
      <IconWrapper class="cursor-pointer" icon="close" @click="abortForm" />
    </div>
    <FormTabs
      :key="formTabsKey"
      :show-tab-headers="!isSingleInstance || showTabs"
      :tabs="tabs"
      :disable-transitions="true"
      content-classes="flex flex-col gap-5"
      :max-height-is-height="formMaxHeightIsHeight"
      @updated:tab="tab = $event"
    >
      <template
        v-for="(tabData, index) in structuredTabData"
        :key="tabData.tab.title"
        #[`slot${index}`]
      >
        <div
          v-for="block in tabData.blocks"
          :key="block"
          :class="['flex flex-col gap-2.5']"
          :style="{ width: block.width }"
        >
          <h5 v-if="block.title" class="text-title-neutral h-5">
            {{ block.title }}
          </h5>
          <div
            :class="[
              'grid mb-5',
              block.gap ? `gap-${block.gap}` : 'gap-4',
              block.flow === 'horizontal'
                ? 'grid-flow-col auto-cols-fr'
                : 'grid-flow-row auto-rows-auto',
            ]"
          >
            <div
              v-for="childBlock in block.children"
              :key="childBlock"
              :class="[
                'flex flex-col',
                childBlock.gap ? `gap-${childBlock.gap}` : 'gap-1.5',
              ]"
              :style="{ width: childBlock.width }"
            >
              <Component
                :is="childBlock.subTitleTag || 'h6'"
                v-if="childBlock.subTitle"
                :class="[
                  childBlock.subTitleColor
                    ? childBlock.subTitleColor
                    : 'text-title-neutral',
                  'whitespace-break-spaces',
                ]"
              >
                {{ childBlock.subTitle }}
              </Component>
              <div
                :class="[
                  'grid gap-3',
                  childBlock.flow === 'horizontal'
                    ? 'grid-flow-col auto-cols-fr'
                    : 'grid-flow-row auto-rows-fr',
                ]"
              >
                <template v-for="field in childBlock.fields" :key="field">
                  <!-- field edge cases start -->
                  <div v-if="field.key === 'years'">
                    <div class="flex mb-2.5">
                      <VRadio
                        v-model="bevSelection"
                        value="factor_per_year"
                        class="mt-3"
                        :disabled="props.readOnly"
                        @click="bevSelection = 'factor_per_year'"
                      />

                      <InputEl
                        v-model="yearFactorFieldBev"
                        label="Jährlicher Entwicklungsfaktor"
                        input-type="float"
                        :disabled="props.readOnly"
                        suffix="% p.a."
                        class="w-full"
                        :positive-only="true"
                        @update:model-value="handleFactorYearFieldUpdate"
                      />
                    </div>
                    <div class="flex">
                      <VRadio
                        v-model="bevSelection"
                        :value="field.key"
                        class="mt-3"
                        :disabled="props.readOnly"
                        @click="bevSelection = field.key"
                      />

                      <div
                        v-if="field.key === 'years'"
                        class="grid grid-flow-col auto-cols-fr gap-2"
                      >
                        <InputEl
                          v-for="item in yearFieldsBev.sort(
                            (a, b) => a.label - b.label,
                          )"
                          :key="item"
                          v-model="item.value"
                          :label="item.label"
                          :disabled="props.readOnly"
                          :positive-only="true"
                          input-type="float"
                          @update:model-value="handleBevYearFieldUpdate(field)"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    v-else-if="field.key === 'pv_roof_include_orientations'"
                    class="flex flex-col gap-2"
                  >
                    <h6 class="text-title-neutral">
                      Berücksichtigungen von Dachausrichtungen
                    </h6>
                    <div class="flex gap-4">
                      <CheckboxEl
                        v-for="(value, key) in field.value.value"
                        :key="key"
                        v-model="field.value.value[key]"
                        :value="value"
                        :disabled="props.readOnly"
                      >
                        <div class="body-2 text-neutral pt-[3px]">
                          {{ key === 'FL' ? 'Flach' : key }}
                        </div>
                      </CheckboxEl>
                    </div>
                  </div>

                  <!-- field edge cases end -->

                  <!-- field custom tag start -->
                  <template v-else-if="field.tag">
                    <div
                      :style="field.wrapperStyle"
                      :class="field.wrapperClass"
                    >
                      <Component :is="field.tag" :class="field.class"
                        >{{ field.content }}
                      </Component>
                    </div>
                  </template>
                  <!-- field custom tag end -->

                  <!-- field normal start -->
                  <template v-else-if="field.value">
                    <CheckboxEl
                      v-if="field.value.type === 'boolean'"
                      v-model="field.value.value"
                      border-radius="1px"
                      :disabled="props.readOnly"
                    >
                      <div
                        class="body-1 mt-0.5"
                        :class="props.readOnly && 'text-disabled'"
                      >
                        {{ field.value.label }}
                      </div>
                    </CheckboxEl>
                    <InputEl
                      v-else
                      v-model="field.value.value"
                      :label="setLabel(field)"
                      :input-type="getFieldType(field.value)"
                      :disabled="props.readOnly"
                      :suffix="field.value.unit"
                      class="w-full"
                      :positive-only="true"
                      :rules="
                        getValidationRules(field.value, filteredInstancesNames)
                      "
                    />
                    <!-- field normal end -->
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </FormTabs>
    <!-- finish-->
    <div class="flex justify-between items-center gap-5">
      <div class="flex gap-5 ml-auto">
        <ButtonEl
          v-if="!isSingleInstance"
          text="Abbrechen"
          color="color2"
          @click="abortForm"
        />
        <ButtonEl
          text="Speichern"
          :disabled="
            readOnly ||
            !fieldValidator.isValid ||
            !nameFieldIsUnique ||
            !bevInputValid ||
            !doesIdMatch
          "
          @click="saveInstance()"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import ButtonEl from '@/components/button/ButtonEl.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InputEl from '@/components/input/InputEl.vue';
import { axios } from '@/utils/axiosHelper';
import { RESTRequestValidator } from '@/utils/rest-request-validator';
import { getFieldType, getValidationRules } from '@/utils/rest-utils';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import cookie from 'vue-cookies';

const emit = defineEmits(['abort', 'update-instances']);

const props = defineProps({
  instanceEditing: {
    type: Object,
  },
  instancesNames: {
    type: Array,
    default: null,
  },
  identifier: {
    type: String,
    default: 'id',
  },
  metaData: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  url: {
    type: String,
    required: true,
  },
  excludeFields: {
    type: Array,
    default: () => [],
  },
  excludeFromRequestIfNull: {
    type: Array,
    default: () => [],
  },
  enableRelease: {
    type: Boolean,
    default: false,
  },
  formTitle: {
    type: String,
    default: null,
  },
  contentStructure: {
    type: Array,
    default: () => [],
  },
  isSingleInstance: {
    type: Boolean,
    default: false,
  },
  cloneExistingInstance: {
    type: Boolean,
    default: false,
  },
  formMaxHeightIsHeight: {
    type: Boolean,
    default: false,
  },
  formTabsKey: {
    type: Number,
    default: 0,
  },
  initialTitle: {
    type: String,
    default: null,
  },
  showTabs: {
    type: Boolean,
    default: false,
  },
});

const structuredFields = ref({});
const fieldValidator = ref({});
const bevInputValid = ref(false);
const bevSelection = ref('factor_per_year');
const yearFactorFieldBev = ref(null);
const yearFieldsBev = ref([
  {
    label: '2024',
    value: null,
  },
  {
    label: '2025',
    value: null,
  },
  {
    label: '2030',
    value: null,
  },
  {
    label: '2035',
    value: null,
  },
  {
    label: '2040',
    value: null,
  },
  {
    label: '2045',
    value: null,
  },
  {
    label: '2050',
    value: null,
  },
]);

const tab = ref();
const tabs = computed(() => structuredTabData.value.map((item) => item.tab));

const parameterSetForm = ref(null);

const instanceEditingLocalId = ref(null);

const doesIdMatch = computed(() => {
  if (!props.cloneExistingInstance) {
    return true;
  }

  return instanceEditingLocalId.value === props.instanceEditing.id;
});

const structuredTabData = computed(() => {
  // Helper function to map fields to reactive objects
  function mapFields(fields, data, removeFromLabel = null) {
    return fields.map((field) => {
      if (typeof field === 'string') {
        return {
          key: field,
          value: data[field] || null,
          removeFromLabel,
        };
      } else if (typeof field === 'object') {
        return field;
      }
    });
  }

  // Helper function to process each block
  function processBlocks(blocks, data) {
    return blocks.map((block) => {
      const newBlock = { ...block };

      // Process children if present
      if (block.children) {
        newBlock.children = block.children.map((child) => {
          const newChild = { ...child };

          // Map fields for each child if present
          if (child.fields) {
            newChild.fields = mapFields(
              child.fields,
              data,
              child.removeFromLabel,
            );
          }

          return newChild;
        });
      }

      return newBlock;
    });
  }

  return props.contentStructure.map((tab) => ({
    ...tab,
    blocks: processBlocks(tab.blocks, structuredFields.value),
  }));
});

onMounted(() => {
  setStructuredFields();
});

function bevFieldsToKeyValueMap(fields) {
  return fields.reduce((acc, field) => {
    acc.push({ year: field.label, bev_development_factor: field.value / 100 });
    return acc;
  }, []);
}

function handleBevYearFieldUpdate(field) {
  const nonNullValues = yearFieldsBev.value.some((item) => item.value !== null);
  if (nonNullValues) {
    field.value.value = bevFieldsToKeyValueMap(yearFieldsBev.value);
    bevSelection.value = field.key;
  }
  bevInputValid.value = yearFieldsBev.value.every(
    (item) => item.value !== null,
  );
}

async function setBevInputValid(valid) {
  // nextTick needed to always execute after bevSelection watcher
  await nextTick();
  bevInputValid.value = valid;
}

function handleFactorYearFieldUpdate(value) {
  structuredFields.value['years'].value = yearFieldsBev.value.map((item) => ({
    year: item.label,
    bev_development_factor: value / 100,
  }));
  bevSelection.value = 'factor_per_year';
  setBevInputValid(Boolean(value));
}

function setLabel(field) {
  return field.removeFromLabel
    ? field.value.label.replace(field.removeFromLabel, '').trim()
    : field.value.label;
}

function processSectorField(field, fields, updatedInstanceEditing) {
  field.child.children.sector.choices.forEach((choice) => {
    for (const [sectorFieldName, sectorField] of Object.entries(
      field.child.children,
    )) {
      if (sectorFieldName === 'sector' || sectorField.read_only) {
        continue;
      }

      fields[`${sectorFieldName}_${choice.value}`] = {
        ...sectorField,
        value: props.instanceEditing
          ? updatedInstanceEditing[`${sectorFieldName}_${choice.value}`]
          : null,
        label: choice.display_name,
        category: 'sector',
      };
    }
  });
}

function processField(fieldName, field, fields, updatedInstanceEditing) {
  if (fieldName === 'sectors') {
    processSectorField(field, fields, updatedInstanceEditing);
  } else {
    fields[fieldName] = {
      ...field,
      value: props.instanceEditing ? updatedInstanceEditing[fieldName] : null,
    };
  }
}

function formatSectorFields() {
  const fieldsToExclude = { id: true, sector: true, assumptions: true };

  const formattedSectorFields = props.instanceEditing.sectors?.reduce(
    (result, sector) => {
      for (const [name, value] of Object.entries(sector)) {
        if (!fieldsToExclude[name]) {
          result[`${name}_${sector.sector}`] = value;
        }
      }
      return result;
    },
    {},
  );

  const updatedInstanceEditing = {
    ...props.instanceEditing,
    ...formattedSectorFields,
  };
  delete updatedInstanceEditing.sectors;

  return updatedInstanceEditing;
}

function setStructuredFields() {
  const fields = {};
  let updatedInstanceEditing = null;

  if (props.instanceEditing) {
    updatedInstanceEditing = formatSectorFields();
  }

  if (!props.metaData.years) {
    bevInputValid.value = true;
  }

  for (const [fieldName, field] of Object.entries(props.metaData)) {
    if (props.excludeFields.includes(fieldName) || field.read_only) {
      continue;
    }
    processField(fieldName, field, fields, updatedInstanceEditing);
  }

  structuredFields.value = fields;
  fieldValidator.value = new RESTRequestValidator(structuredFields.value);

  if (props.initialTitle) {
    structuredFields.value.title.value = props.initialTitle;
  }

  if (props.cloneExistingInstance) {
    saveInstance();
  }
}

function abortForm() {
  emit('abort', false);
}

function initPayloadSectors() {
  if (props.metaData.sectors) {
    return Array.from({ length: 5 }, (_, index) => ({
      sector: index,
      assumptions: props.instanceEditing
        ? props.instanceEditing.sectors[0].assumptions
        : null,
    }));
  }
  return null;
}

function getPayload() {
  const payload = {};
  const sectors = initPayloadSectors();

  for (const [fieldName, field] of Object.entries(structuredFields.value)) {
    if (
      props.excludeFromRequestIfNull.includes(fieldName) &&
      field.value === null
    )
      continue;

    if (field.category === 'sector') {
      const sectorIndex = fieldName.charAt(fieldName.length - 1);
      sectors[sectorIndex][fieldName.slice(0, -2)] = field.value;
    } else {
      payload[fieldName] = field.value;
    }
  }

  if (props.metaData.sectors) {
    payload.sectors = sectors;
  }
  return payload;
}

async function saveInstance() {
  let url;
  let method;
  if (props.instanceEditing !== null && !props.cloneExistingInstance) {
    url = `${props.url}${props.instanceEditing[props.identifier]}/`;
    method = 'PUT';
  } else {
    url = props.url;
    method = 'POST';
  }

  try {
    const resp = await axios({
      url,
      method,
      data: getPayload(),
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    });

    emit('update-instances', method === 'POST' && resp.data.id);

    if (props.cloneExistingInstance) {
      instanceEditingLocalId.value = resp.data.id;
    } else {
      abortForm();
    }
  } catch (error) {
    console.error('Error saving instance:', error);
  }
}

watch(
  () => props.instanceEditing,
  () => {
    setStructuredFields();
  },
);

watch(bevSelection, (newValue) => {
  if (newValue === 'factor_per_year') {
    yearFieldsBev.value.forEach((item) => (item.value = null));
  } else if (newValue === 'years') {
    yearFactorFieldBev.value = null;
  }

  structuredFields.value['years'].value = [];
  bevInputValid.value = false;
});

watch(
  () => structuredFields.value['years'],
  (newValue) => {
    if (
      newValue &&
      typeof newValue.value === 'object' &&
      newValue.value !== null
    ) {
      yearFieldsBev.value = newValue.value.map((item) => ({
        label: String(item.year),
        value: item.bev_development_factor * 100,
      }));
      bevSelection.value = 'years';
      setBevInputValid(true);
    }
  },
);

// initialize roof orientations for fresh instance
watch(
  () => structuredFields.value['pv_roof_include_orientations'],
  (newValue) => {
    if (!newValue.value) {
      const choices = newValue.choices;

      structuredFields.value['pv_roof_include_orientations'].value =
        choices.reduce((acc, choice) => {
          acc[choice.value] = choice.value !== 'N';
          return acc;
        }, {});
    }
  },
);

const filteredInstancesNames = computed(() =>
  props.instancesNames.filter((name) => name !== props.instanceEditing?.title),
);

const nameFieldIsUnique = computed(() => {
  if (props.isSingleInstance) {
    return true;
  }
  const titleValue = structuredFields.value?.title?.value;

  // Check if the title value is defined
  if (titleValue === undefined || titleValue === null || titleValue === '') {
    return false;
  }

  // Normalize the title value
  const normalizedTitle = titleValue
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '');

  // Normalize the instancesNames
  const normalizedInstanceNames = filteredInstancesNames.value.map((name) =>
    name.toString().toLowerCase().trim().replace(/\s+/g, ''),
  );

  // Check if the normalized title is unique
  return !normalizedInstanceNames.includes(normalizedTitle);
});
</script>

<template>
  <div v-if="isLoading" class="flex justify-center items-center py-24">
    <LoadingSpinner />
  </div>
  <template v-else>
    <div class="space-y-2.5 py-5">
      <DetailBox
        icon="heat_pump"
        label="Anzahl potenzielle Wärmepumpen"
        :value="exportData.count_air_heat_pump_potential_green"
        :maximum-digits="0"
        :minimum-digits="0"
      />
      <DetailBox icon="traffic" label="Wärmepumpenampel">
        <template #content>
          <div class="flex w-full justify-end align-center">
            <div
              v-for="item in heatTrafficLights"
              :key="item"
              class="min-w-[50px] h-[30px] p-[5px] text-disabled-warning ml-[5px]"
              :class="item.background"
            >
              <h4>{{ item.value }} %</h4>
            </div>
          </div>
        </template>
      </DetailBox>
      <div
        v-for="column in cardsColumn3"
        :key="column"
        class="grid auto-cols-fr grid-flow-col gap-2.5"
      >
        <DetailBox
          v-for="item in column"
          :key="item.label"
          :maximum-digits="0"
          :minimum-digits="0"
          v-bind="item"
          class="w-full"
        />
      </div>
      <DetailBox
        icon="inventory_2"
        label="Commoditiy-Potenzial"
        :value="exportData.commodity_potential_eur_a"
        :maximum-digits="0"
        :minimum-digits="0"
        unit="kw"
      />
    </div>
    <div class="grid grid-cols-3 h-[400px] px-5 gap-2.5">
      <BasicPieChart
        class="h-full"
        :chart-data="pieSectorData"
        title="Gebäude nach Sektor"
        :custom-radius="['35%', '55%']"
        :series-position="['50%', '36%']"
        legend-orient="horizontal"
        chart-unit="Gebäude"
        break-unit
        legend-top="255px"
        :legend-right="null"
        :legend-line-break="false"
        :show-label="false"
        custom-title-tag="h5"
        custom-title-classes="min-h-9"
      />
      <BasicPieChart
        class="h-full"
        :chart-data="pieYearData"
        :title="'Gebäude nach\nBaualtersklassen'"
        :custom-radius="['35%', '55%']"
        :series-position="['50%', '36%']"
        legend-orient="horizontal"
        chart-unit="Gebäude"
        break-unit
        legend-top="255px"
        :legend-right="null"
        :legend-line-break="false"
        :show-label="false"
        custom-title-tag="h5"
        custom-title-classes="min-h-9"
      />
      <BasicPieChart
        class="w-[300px] h-full"
        :chart-data="pieSourceData"
        :title="'Gebäude nach\n Energieträger'"
        :custom-radius="['35%', '55%']"
        :series-position="['50%', '36%']"
        legend-orient="horizontal"
        chart-unit="Gebäude"
        break-unit
        legend-top="255px"
        :legend-right="null"
        :legend-line-break="false"
        :show-label="false"
        custom-title-tag="h5"
        custom-title-classes="min-h-9"
      />
    </div>
    <div
      class="bg-default rounded-md standard-elevation-0-dark px-[14px] pt-2.5 pb-5 mb-5 text-title-neutral"
    >
      <div class="flex justify-between">
        <div class="flex align-center">
          <IconWrapper
            :icon="getListProgressIcon(leadList.state).icon"
            :size="32"
          />
          <h4 class="ml-2.5">
            {{ getListProgressIcon(leadList.state).label }}
          </h4>
        </div>
        <IconWrapper
          icon="edit_note"
          :size="40"
          class="hover:cursor-pointer text-title-color1 hover:text-button-primary-hover-color1"
          @click="showStatusDialog = true"
        />
        <VuetifyDialog
          v-model="showStatusDialog"
          title="Status setzen"
          width="628px"
        >
          <template #content>
            <VRadioGroup v-model="selectedStatus">
              <div class="space-y-2 mb-4">
                <VRadio
                  v-for="option in statusOptions"
                  :key="option.value"
                  :value="option.value"
                  @click="selectedStatus = option.value"
                >
                  <template #label>
                    <h5 class="ml-2 mr-2 text-neutral">{{ option.label }}</h5>
                  </template>
                </VRadio>
              </div>
            </VRadioGroup>
            <TextAreaEl label="Feedback" custom-classes="h-52" />
          </template>
          <template #actions>
            <div class="w-full flex justify-between">
              <ButtonEl
                text="Abbrechen"
                color="color2"
                @click="showStatusDialog = false"
              />
              <ButtonEl text="Speichern" @click="editStatus" />
            </div>
          </template>
        </VuetifyDialog>
      </div>
      <p class="body-1 mt-2.5">
        {{ leadList.description || 'Kein Feedback vorhanden' }}
      </p>
    </div>
    <div
      class="flex justify-between bg-default rounded-md standard-elevation-0-dark px-[14px] py-5 mb-5 text-title-neutral"
    >
      <div class="flex align-center">
        <IconWrapper icon="file_download" :size="32" />
        <p class="body-1 text-neutral my-[5px]">Export</p>
        <!-- Open to top, otherwise opened options get cut for last 2 table entries -->
        <DropDown
          v-model="selectedFormat"
          :open-to-top="true"
          class="w-[350px] ml-4"
          :initial-selection="true"
          :items-data="downloadFormats"
          max-height-dropdown="200px"
          items-data-key="label"
        />
      </div>
      <ButtonEl text="Download" @click="downloadLeadList" />
    </div>
  </template>
</template>

<script setup>
import DetailBox from '@/apps/features/boxes/DetailBox.vue';
import IconHeatWavesCircleOutlined from '@/assets/icons/custom/energy-grid/IconHeatWavesCircleOutlined.vue';
import IconHeatWavesCircleFilled from '@/assets/icons/custom/energy-grid/IconHeatWavesCircleFilled.vue';
import IconHousePointFilled from '@/assets/icons/custom/energy-grid/IconHousePointFilled.vue';
import IconHousePointOutlined from '@/assets/icons/custom/energy-grid/IconHousePointOutlined.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import { computed, ref, onMounted } from 'vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import TextAreaEl from '@/components/textAreaEl/TextAreaEl.vue';
import { getListProgressIcon } from '@/apps/usecase-4/utils';
import axios from '@/utils/axiosHelper';
import {
  CONSTRUCTION_YEAR_UC4_BY_ENUM,
  HEATING_TYPE_BY_ENUM,
  SECTOR_BY_ENUM,
} from '@/configs/color-constants';
import LoadingSpinner from '@/components/loading-spinner/LoadingSpinner.vue';

const props = defineProps({
  leadList: {
    type: Object,
    required: true,
  },
});

const isLoading = ref(true);
const exportData = ref({});

onMounted(async () => {
  try {
    const { data } = await axios({
      url: `/api/usecase-4/export/lead-list-aggregation/${props.leadList.id}`,
      method: 'GET',
    });
    exportData.value = data;
  } finally {
    isLoading.value = false;
  }
});

const selectedFormat = ref(null);
const selectedStatus = ref('open');
const showStatusDialog = ref(false);

const statusOptions = [
  {
    label: 'Offen',
    value: 'open',
  },
  {
    label: 'Versandt an Partner',
    value: 'sent',
  },
  {
    label: 'Abgeschlossen',
    value: 'finalised',
  },
];

const downloadFormats = ref([
  {
    label: 'CSV',
    value: 'csv',
  },
  {
    label: 'Als GeoJSON (Projektion EPSG:4326)',
    value: 'geojson',
  },
  {
    label: 'Als ESRI Shapefile (Projektion EPSG:4326)',
    value: 'shapefile',
  },
  {
    label: 'Report als PPTX',
    value: 'pptx',
  },
]);

const cardsColumn3 = computed(() => [
  [
    {
      icon: IconHeatWavesCircleOutlined,
      label: 'Wärmebedarf',
      value: exportData.value.demand_kwh,
      unit: 'MWh(th)/a',
    },
    {
      icon: IconHousePointOutlined,
      label: 'Ø Wärmebedarf je Gebäude',
      value: exportData.value.average_demand_kwh,
      unit: 'kWh(th)/a',
    },
  ],
  [
    {
      icon: IconHeatWavesCircleFilled,
      label: 'Wärmelast',
      value: exportData.value.demand_kw,
      unit: 'MW(th)',
    },
    {
      icon: IconHousePointFilled,
      label: 'ø Wärmelast je Gebäude',
      value: exportData.value.average_demand_kw,
      unit: 'KW(th)',
    },
  ],
]);

const heatTrafficLights = computed(() => [
  {
    value: exportData.value.percentage_air_heat_pump_potential_green,
    background: 'bg-spot-success',
  },
  {
    value: exportData.value.percentage_air_heat_pump_potential_yellow,
    background: 'bg-spot-warning',
  },
  {
    value: exportData.value.percentage_air_heat_pump_potential_red,
    background: 'bg-spot-error',
  },
]);
const getPieData = (group, dataKey, style) => {
  return exportData.value[group].map((e) => {
    const choice = style[e[dataKey]];
    return {
      value: e.count_buildings,
      color: choice.color,
      name: choice.label,
    };
  });
};
const pieSectorData = computed(() => {
  return getPieData(
    'by_construction_year_class',
    'construction_year_class_uc4',
    CONSTRUCTION_YEAR_UC4_BY_ENUM,
  );
});
const pieYearData = computed(() => {
  return getPieData('by_sector', 'sector', SECTOR_BY_ENUM);
});
const pieSourceData = computed(() => {
  return getPieData('by_heating_type', 'heating_type', HEATING_TYPE_BY_ENUM);
});

function downloadLeadList() {
  // TODO: Download Logic
}

function editStatus() {
  // TODO: editStatus Logic
}
</script>

<style scoped lang="scss">
:deep(.v-selection-control__wrapper) {
  width: 16px;
}
</style>

import { markRaw } from 'vue';
import IconLineCalc from '@/assets/icons/custom/energy-grid/IconLineCalc.vue';
import { toLocaleStr } from '@/utils/formatUtils';

export const projectHeaders = [
  {
    title: 'Projektname',
    key: 'name',
    align: 'left',
    sortable: false,
  },
  {
    title: 'Analysetyp',
    key: 'is_type',
    align: 'left',
    sortable: false,
  },
  {
    title: 'Gemeinde, Landkreis etc.',
    key: 'municipalities',
    align: 'left',
    sortable: false,
  },
  {
    title: 'Fläche',
    key: 'potential_area_m2',
    align: 'end',
    sortable: false,
    unit: 'ha',
    multiplier: 0.0001,
  },
  {
    title: 'Flur-\nstücke',
    key: 'count_estates',
    align: 'end',
    sortable: false,
  },
  {
    title: 'Leistung\nPV',
    key: 'power_kw',
    align: 'end',
    sortable: false,
    unit: 'MW',
  },
  {
    title: 'Länge Netz-\nanschluss',
    key: 'connection_length_m',
    align: 'end',
    sortable: false,
    unit: 'm',
  },
  {
    title: 'Leistung\nBatteriesp.',
    key: 'battery_capacity_kw',
    align: 'end',
    sortable: false,
    unit: 'MW',
  },
  {
    title: 'Spezifische\nInvestition',
    key: 'specific_investment_eur_kw',
    align: 'end',
    sortable: false,
    unit: '€/kW',
  },
  {
    title: 'Spannungs-\nebene',
    key: 'voltage_level',
    align: 'end',
    sortable: false,
  },
  {
    title: 'Investitions-\nvolumen',
    key: 'total_investment_cost_eur',
    align: 'end',
    sortable: false,
    unit: 'Mio. €',
    maxDigits: 1,
  },
  {
    title: 'Wert-\nhaltigkeit',
    key: 'utility_value',
    align: 'center',
    sortable: false,
  },
  {
    title: '',
    key: 'review_state',
    align: 'center',
    sortable: false,
  },
  {
    title: '',
    key: 'is_favorite',
    align: 'center',
    sortable: false,
  },
  {
    title: '',
    key: 'functions',
    align: 'end',
    sortable: false,
  },
];

export const configTopField = (projectAggregationData) => [
  [
    {
      label: 'Anzahl Einzelprojekte',
      value: toLocaleStr(projectAggregationData.value.count_projects),
      icon: 'flip_to_front',
    },
  ],
  [
    {
      label: 'Gesamtfläche (ha)',
      value: toLocaleStr(projectAggregationData.value.area_m2),
      icon: 'select_all',
    },
    {
      label: 'Anzahl betroffener Flurstücke',
      value: toLocaleStr(projectAggregationData.value.count_estates),
      icon: 'layers',
    },
  ],

  [
    {
      label: 'Installierbare Leistung PV (MW)',
      value: toLocaleStr(projectAggregationData.value.power_kw),
      icon: 'solar_power',
      maxDigits: 0,
    },
  ],

  [
    {
      label: 'Investitionskosten Netzanschluss (Mrd. €)',
      value: toLocaleStr(
        (projectAggregationData.value.investment_cost_hv_connection_eur +
          projectAggregationData.value.investment_cost_cable_eur +
          projectAggregationData.value.investment_cost_underground_eur) /
          1000,
        2,
        2,
      ),
      icon: markRaw(IconLineCalc),
    },
    {
      label: 'Investitionskosten Anlage (Mrd. €)',
      value: toLocaleStr(
        (projectAggregationData.value.investment_cost_pv_eur +
          projectAggregationData.value.investment_cost_battery_eur) /
          1000,
        2,
        2,
      ),
      icon: 'euro',
    },
    {
      label: 'Investitionskosten (Mrd. €)',
      value: toLocaleStr(
        Object.keys(projectAggregationData.value)
          .filter((key) => key.startsWith('investment_'))
          .reduce((sum, key) => sum + projectAggregationData.value[key], 0) /
          1000,
        2,
        2,
      ),
      icon: 'euro',
    },
  ],
];

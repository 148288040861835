import { LAYER_KEY__BUILDINGS } from '@/apps/usecase-4/map/layer-config/buildings';
import {
  LAYER_KEY__COUNTY,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__MUNICIPALITY,
} from '../layer-config/geographical-level';
import { LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS } from '../layer-config/infrastructure';

const municipalityAndHigher = [
  LAYER_KEY__MUNICIPALITY,
  LAYER_KEY__COUNTY,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
];

const customAndAgreggationToggle = ({
  onLayer,
  icon,
  aggregationOnLayers,
}) => ({
  itemClass: 'toggle',
  aggregation: {
    includeCustomToggle: {
      onLayer,
      icon,
    },
    includeAggregationToggle: !aggregationOnLayers
      ? {
          partial: false,
        }
      : {
          partial: true,
          onLayers: aggregationOnLayers,
        },
  },
});

const customToggle = ({ onLayer, icon }) => ({
  itemClass: 'toggle',
  aggregation: {
    includeCustomToggle: {
      onLayer,
      icon,
    },
  },
});

export const layerPanelConfig = {
  sections: [
    {
      title: 'Basisdaten',
      groups: [
        {
          title: 'Gebäudestruktur',
          id: 'buildingStructure',
          items: [
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Keine Einfärbung',
              layerState: 'default',
            },
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Sektor',
              layerState: 'sector',
            },
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Baualtersklasse',
              layerState: 'constructionYearClass',
            },
            {
              ...customToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Gebäudetyp',
              layerState: 'buildingType',
            },
            {
              ...customToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Eigentümerquote',
              layerState: 'ownershipRate',
            },
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'EnBW-Kunde',
              layerState: 'customerAt',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Nachfrage',
      groups: [
        {
          title: 'Wärme',
          id: 'heat',
          items: [
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Wärmebedarf',
              layerState: 'heat_demand_total_kwh_a',
            },
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Wärmelast',
              layerState: 'heat_demand_total_kw',
            },
            {
              ...customToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Wärmeliniendichte',
              layerState: 'heat_line_density_kwh_am',
            },
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Energieträger',
              layerState: 'heating_technology',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Erzeugung',
      groups: [
        {
          title: 'Potenzial',
          id: 'potential',
          items: [
            {
              title: 'PV-Dach',
              id: 'potentialPvRoof',
              items: [
                {
                  ...customAndAgreggationToggle({
                    onLayer: LAYER_KEY__BUILDINGS,
                    icon: 'maps_home_work',
                  }),
                  title: 'Leistung',
                  layerState: 'potentialPvRoofKw',
                },
              ],
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Infrastruktur',
      groups: [
        {
          title: 'Wärme ',
          id: 'InfrastructureHeat',
          items: [
            {
              ...customToggle({
                onLayer: LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS,
                icon: 'shape_line',
              }),
              title: 'Wärmeliniendichte',
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
  ],
};
